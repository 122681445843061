import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Route, Router, Switch } from 'react-router';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Track from './pages/track/Track';


import { history } from './_helpers';


import './index.css';

declare global {
    interface Window { google: any; account:any;}
}
window.google = window.google || {};
window.account = window.account || {};


ReactDOM.render(
	<Router history={history}>
		<div id="app-root">
			<Switch>

				<Route path="/track" component={Track} ></Route>
				<Route path="/login" component={Login} />
				<Route path="/" component={Home}></Route>

			</Switch>
		</div>
	</Router>,
	document.getElementById('root') as HTMLElement
);

