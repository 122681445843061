var taskTypes =  [
    {
      "value": 21,
      "label": "Concierge"
    },
    {
      "value": 19,
      "label": "Run"
    },
    {
      "value": 34,
      "label": "Monsey Run"
    },
    {
      "value": 20,
      "label": "Staffing"
    },
    {
      "value": 32,
      "label": "Transport"
    },
    {
      "value": 22,
      "label": "Transport Courier"
    },
    {
      "value": 23,
      "label": "Transport VIP"
    },
    {
      "value": 33,
      "label": "Travel"
    }
  ]

module.exports = taskTypes;
