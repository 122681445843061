import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import * as controller from '../shop/controller'
import { toast } from 'react-toastify';

export default class Shops extends React.Component<any, any> {
  searchString: any

  constructor(props: any) {
    super(props);
    this.state = {
      shops: [],
      shopsFiltered: []
    }
  }

  search() {
      let shopsFiltered = this.state.shopsFiltered
      shopsFiltered = this.state.shops.filter((shop: any) => {
        return shop.name.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
      })
      this.setState({shopsFiltered})
  }

  loadShops() {
    fetch('shops/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let shops = data.shops
        this.setState({shops, shopsFiltered: shops})
    }).catch(err => {
      toast.error(err.message);
    })
  }

  componentDidMount() {

  }

  componentWillMount() {
    this.loadShops()
  }

  componentWillUnmount() {
  }


  public render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'E-Mail',
      accessor: 'email'
    }, {
      Header: 'Phone',
      accessor: 'phone'
    }, {
      id: 'settings',
      Header: 'Settings',
      sortable: false,
      maxWidth: 100,
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>
        <Link to={"/shops/" + row.value.id}><i className="fas fa-edit"></i></Link>
        <i onClick={controller.removeShop.bind(this, row.value.id, row.index)} className="fas fa-trash-alt"></i>
      </div>
    }]

    return (
      <div id="users-root">
        <input className="search" type="text" placeholder="Search shop by name" onChange={this.search.bind(this)} ref={(searchString:any) => this.searchString = searchString} />

        <div className="board">
          <ReactTable
            data={this.state.shopsFiltered}
            columns={columns}
            noDataText="No shops found"
            pageSizeOptions={[100,300,500,1000]}
            defaultPageSize={100}
            style={{
              height: "calc(100vh - 120px)",
              textAlign: "center",
              fontSize: "1em",
              position: "centner",
              right: "0",
              left: "0",
              margin: "0 auto"
            }}
            className="-striped -highlight reactTable"
          />
        </div>
      </div>
    )
  }

}
