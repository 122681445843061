export const getLastAddressId = (taskOfType: any) => {
    console.log('aaa taskOfType',taskOfType)
  let res = 1;
  taskOfType.forEach((e: any) => {
    if (e.id) {
      if (e.id > res) {
        res = e.id;
      }
    } else {
      if (e.orderId && e.orderId > res) {
        res = e.orderId;
      }
    }
  });
  return res;
};
