import * as React from 'react';
import '../task/popups/popup.css';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {customSelectStyles, themeStyles} from '../task/themeStyles.js'
import Select from 'react-select';
import { toast } from 'react-toastify';

export default class Contact extends React.Component<any, any> {
  closePopup: any;
  senderName: any;
  constructor(props: any) {
    super(props);
    this.state = {
      chat: null,
      updating: false,
      contact: {
        type: {value: null, label: null},
        id: null,
        name: null,
        phone: null,
        email: null
      }
    }
    this.closePopup = this.props.closePopup
  }


  componentDidMount() {
    this.setState({chat: this.props.chat}, () => {
      let contactType = this.state.contact.type
      if(this.state.chat.provider) {contactType.value = "provider"; contactType.label = "Provider" }
      if(this.state.chat.client) {contactType.value = "client"; contactType.label = "Client"}
      if(this.state.chat.subProvider) {contactType.value = "subProvider"; contactType.label = "subProvider"}
      if(this.state.chat.subClient) {contactType.value = "subClient"; contactType.label = "subClient"}

      if(contactType) {
        this.setState({contact: Object.assign({type: contactType, phone: this.state.chat.phone},this.state.chat[contactType.value])});
      }
    })
  }


  editPhone(phoneNum:any) {
    let contact = this.state.contact
    contact.phone = phoneNum
    this.setState({contact});
  }

  handleChange(event: any) {
    let contact = this.state.contact
    contact[event.target.name] = event.target.value
    this.setState({contact});
  }


  save(event:any) {
    event.preventDefault()
    this.setState({updating: true})
    let url = ""
    let contactType = this.state.contact.type.value
    switch(contactType) {
      case 'client': url = 'clients/edit'; break;
      case 'provider': url = 'providers/edit'; break;
      default: break;
    }
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(this.state.contact)
    }).then(results => {
      return results.json();
    }).then(data => {
      if(!this.state.contact.id) {
        toast.success(this.state.contact.type.label+" created successfuly")
        this.closePopup()
      } else {
        toast.success(this.state.contact.type.label+" updated successfuly")
        this.closePopup()
      }
    }).catch(err => {
      this.setState({updating: false})
      toast.error(err.message);
    })


  }


  public render() {

    return (
      <div id="popup-root">

        <i onClick={this.closePopup} className="fas fa-times"></i>

        {this.state.chat && <div className="container">
          <Select
            theme={themeStyles}
            styles={customSelectStyles}
            isDisabled={this.state.contact.id? true : false}
            className="multiSelect"
            classNamePrefix="react-select"
            value={this.state.contact.type.value? this.state.contact.type : null}
            onChange={(selectedOption: any) => {
              let contact = this.state.contact
              contact.type = selectedOption
              this.setState({contact})
            }}
            options={[
              {value: "client", label: "Client"},
              {value: "provider", label: "Provider"},
              {value: "subClient", label: "Sub Client"},
              {value: "subProvider", label: "Sub Provider"}
            ]}
            menuPlacement={"bottom"}
            placeholder={"Type"}
          />
          <input className="third" onChange={this.handleChange.bind(this)} defaultValue={this.state.contact.name} name="name" placeholder="Name" />
          <PhoneInput
            className="third"
            country="US"
            displayInitialValueAsLocalNumber
            placeholder="Phone"
            value={ this.state.contact.phone? this.state.contact.phone : this.state.chat.phone }
            onChange={ (value:any) => this.editPhone(value) }/>
          <input className="third" onChange={this.handleChange.bind(this)} defaultValue={this.state.contact.email} name="email" placeholder="E-Mail" />

          <button className={"update"} onClick={this.save.bind(this)} disabled={this.state.updating}>
            {this.state.updating? "Updating" : "Save"}
          </button>

        </div>}


      </div>
    )
  }

}
