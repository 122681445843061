import * as React from 'react';
//import Select from 'react-select';
import { Link } from 'react-router-dom';
import {Client} from '../../_models/Client'
//import {customSelectStyles} from './selectStyles.js'
import * as controller from './controller'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';

const Loader = require('react-loader-spinner').default;

export default class ClientPage extends React.Component<any, any> {

  handleClientChange: any
  handleSelectChange: any
  updateClient:any
  editPhone: any
  editSubPhone: any
  constructor(props: any) {
    super(props);
    this.state = {
      client: new Client(),
      updating: false
    }
    this.updateClient = controller.updateClient.bind(this)
    this.handleClientChange = controller.handleClientChange.bind(this)
    this.editPhone = controller.editPhone.bind(this)
    this.editSubPhone = controller.editSubPhone.bind(this)

  }



  componentDidMount() {
    if(this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadClient(this.props.match.params.id)
    }

  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.client.id != nextProps.match.params.id) {
      this.loadClient(nextProps.match.params.id)
    } else {
      this.setState({client: new Client()})
    }

  }


  addSubClient(event:any) {
    event.preventDefault()
    let client = this.state.client
    client.subClients.push({name: "", email: "", phone: ""})
    client.updated = false
    this.setState({client})
  }

  addProject(event:any) {
    event.preventDefault()
    let client = this.state.client
    client.projects.push({name: "", email: "", phone: ""})
    client.updated = false
    this.setState({client})
  }



  loadClient(id:any) {
    fetch('clients/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let client = new Client(data.client)
        this.setState({client})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  public render() {
    let client = this.state.client

    return (
      <div id="edit-account-root">
      {this.state.updating && <div className="loadingDiv">
        <Loader
           type="Oval"
           color="#e13c34"
           height="100"
           width="100"
        />
      </div>}
          <Link to="/clients"><i className="fas fa-times"></i></Link>
          <form className="data">
          <input className="third" onChange={this.handleClientChange} defaultValue={client.name} name="name" placeholder="Client's Name" />
          <PhoneInput
            className="third"
            country="US"
            displayInitialValueAsLocalNumber
            placeholder="Client's Phone"
            value={ client.phone }
            onChange={ (value:any) => this.editPhone(value) }/>
          <input className="third" onChange={this.handleClientChange} defaultValue={client.email} name="email" placeholder="Client's E-Mail" />

          <h2>Sub Clients</h2>
          {client.subClients.map((subClient: any, j:number) => {

            return (
              <div key={j} className="subProvider">
                <input className="third" onChange={controller.handleSubClientChange.bind(this, j)} value={subClient.name || ""} name="name" placeholder="Sub Client's Name" />
                <PhoneInput
                  className="third"
                  country="US"
                  displayInitialValueAsLocalNumber
                  placeholder="Sub Client's Phone"
                  value={ subClient.phone || ""}
                  onChange={ (value:any) => this.editSubPhone(j, value) }/>
                <input className="third" onChange={controller.handleSubClientChange.bind(this, j)} value={subClient.email || ""} name="email" placeholder="Sub Client's E-Mail" />
                {!subClient.id && <div className="QBSub"><label>Add to QuickBooks?</label><input onClick={(event:any) => {
                  let client = this.state.client
                  client.subClients[j].addToQB = event.target.checked
                  this.setState({client})
                }} type="checkbox" /></div>}
                <button className="removeSub" onClick={controller.removeSubClient.bind(this, subClient.id, j)}>-</button>
                <div className="clearfix"></div>
              </div>
            )
          })}
          <div className="clearfix"></div>

          <button className="addSub" onClick={this.addSubClient.bind(this)}>+ Add a new sub client</button>

          <h2>Projects</h2>
          {client.projects.map((project: any, j:number) => {

            return (
              <div key={j} className="project">
                <input className="full" onChange={controller.handleProjectChange.bind(this, j)} value={project.name} name="name" placeholder="Projects's Name" />
                <button className="removeSub" onClick={controller.removeProject.bind(this, project.id, j)}>-</button>
                <div className="clearfix"></div>
              </div>
            )
          })}
          <button className="addSub" onClick={this.addProject.bind(this)}>+ Add a new project</button>


            <button className={"update "+((client.id && client.updated)? "updated" : "")} onClick={this.updateClient} disabled={client.updated || this.state.updating}>
              {this.state.updating? "Updating" :
                client.id? client.updated? "Updated" :
                "Update" :
                "Save"}
            </button>
          </form>
        </div>
    )
  }
}
