import * as React from 'react';
//import Select from 'react-select';
import { Link } from 'react-router-dom';
import {Shop} from '../../_models/Shop'
//import {customSelectStyles} from './selectStyles.js'
import * as controller from './controller'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const Loader = require('react-loader-spinner').default;
import { toast } from 'react-toastify';

export default class ShopPage extends React.Component<any, any> {

  handleShopChange: any
  handleSelectChange: any
  updateShop:any
  editPhone: any
  constructor(props: any) {
    super(props);
    this.state = {
      shop: new Shop(),
      updating: false
    }
    this.updateShop = controller.updateShop.bind(this)
    this.handleShopChange = controller.handleShopChange.bind(this)
    this.editPhone = controller.editPhone.bind(this)
  }



  componentDidMount() {
    if(this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadShop(this.props.match.params.id)
    }

  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.shop.id != nextProps.match.params.id) {
      this.loadShop(nextProps.match.params.id)
    } else {
      this.setState({shop: new Shop()})
    }

  }



  loadShop(id:any) {
    fetch('shops/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let shop = new Shop(data.shop)
        this.setState({shop})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  public render() {
    let shop = this.state.shop
    return (
      <div id="edit-account-root">
          {this.state.updating && <div className="loadingDiv">
            <Loader
               type="Oval"
               color="#e13c34"
               height="100"
               width="100"
            />
          </div>}
          <Link to="/shops"><i className="fas fa-times"></i></Link>
          <form className="data">
            <input className="third" onChange={this.handleShopChange} defaultValue={shop.name} name="name" placeholder="Shop's Name" />
            <PhoneInput
              className="third"
              country="US"
              displayInitialValueAsLocalNumber
              placeholder="Shop's Phone"
              value={ shop.phone }
              onChange={ (value:any) => this.editPhone(value) }/>
            <input className="third" onChange={this.handleShopChange} defaultValue={shop.email} name="email" placeholder="Shop's E-Mail" />
            <button className={"update "+((shop.id && shop.updated)? "updated" : "")} onClick={this.updateShop} disabled={shop.updated || this.state.updating}>
              {this.state.updating? "Updating" :
                shop.id? shop.updated? "Updated" :
                "Update" :
                "Save"}
            </button>
          </form>
        </div>
    )
  }
}
