import * as React from 'react';
import './popup.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify';

export class ClientNotifications extends React.Component<any, any> {
  closePopup: any;
  constructor(props: any) {
    super(props);
    this.state = {
      task: null,
      selectedAll: false,
      newNotification: {
        name: "",
        email: "",
        phone: "",
        emailEnabled: false,
        phoneEnabled: true, //True by default
        assigned: false,
        eta: false,
        location: false,
        completed: false,
        rating: false
      }
    }
    this.closePopup = this.props.closePopup
    this.editNotification = this.editNotification.bind(this)
  }


  componentDidMount() {
    this.setState({
      task: this.props.task
    })
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      task: nextProps.task
    })
  }

  editNotification(event:any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let newNotification = this.state.newNotification
    newNotification[name] = value
    if(name === "rating") {
      newNotification.completed = false;
    }
    if(name === "completed") {
      newNotification.rating = false;
    }
    this.setState({newNotification});
  }

  editPhone(phoneNum:any) {
    let newNotification = this.state.newNotification
    newNotification.phone = phoneNum
    this.setState({newNotification});
  }

  addNotification() {
    if(this.state.newNotification.name.length == 0 ||
      (this.state.newNotification.phone.length == 0 &&
        this.state.newNotification.email.length == 0)) {
          return
        }
    let task = this.state.task
    task.notifications.push(this.state.newNotification)
    task.updated = false
    this.setState({task: task, newNotification: {
      name: "",
      email: "",
      phone: "",
      emailEnabled: false,
      phoneEnabled: true, //True by default
      assigned: false,
      eta: false,
      location: false,
      completed: false,
      rating: false
    }})
  }

  selectAll() {
    let notification = this.state.newNotification
    notification.assigned = true
    notification.eta = true
    notification.location = true
    notification.completed = false
    notification.rating = true
    this.setState({newNotification: notification, selectedAll: true})
  }

  deselectAll() {
    let notification = this.state.newNotification
    notification.assigned = false
    notification.eta = false
    notification.location = false
    notification.completed = false
    notification.rating = false
    this.setState({newNotification: notification, selectedAll: false})
  }

  deleteNotification(index: number) {
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    let id = this.state.task.notifications[index].id
    if(!id) {
      let task = this.state.task
      task.notifications.splice(index, 1)
      this.setState({task: task})
    } else {
      fetch('tasks/deleteNotification', {
        method: 'POST',
        body: JSON.stringify({
          id: this.state.task.notifications[index].id
        })
      }).then(results => {
        let task = this.state.task
        task.notifications.splice(index, 1)
        this.setState({task: task})
        toast.success("Notification deleted")
      }).catch(err => {
        toast.error(err.message);
      })
    }
  }

  toggleNotification(index: number,event:any) {
    let name = event.target.name
    let task = this.state.task
    let notification = task.notifications[index]
    task.updated = false
    notification[name] = !notification[name]
    if(name === "rating" && notification.rating) {
      notification.completed = false;
    }
    if(name === "completed" && notification.completed) {
      notification.rating = false;
    }
    this.setState({task: task})
  }

  autofill() {
    let client = this.state.task.subClient? this.state.task.subClient : this.state.task.client
    let newNotification = this.state.newNotification
    newNotification.name = client.name || ""
    newNotification.phone = client.phone || ""
    newNotification.email = client.email || ""
    this.setState({newNotification})
  }

  public render() {
    return (
      <div id="popup-root" style={{height: "580px"}} className="notifications">
          <i onClick={this.closePopup} className="fas fa-times"></i>

          <div className="container">
            <h2>Set Client Notifications:</h2>
            <div className="set">
              {this.state.task && this.state.task.notifications.map((notification: any, i:number) => {
                  return (
                    <div className="input" key={i}>
                      <i onClick={this.deleteNotification.bind(this, i)} className="fas fa-trash"></i>
                      <span className="name">Name: {notification.name}</span>
                      {window.innerWidth<=768 && <br />}<input name="phoneEnabled" type="checkbox" onChange={this.toggleNotification.bind(this, i)} checked={notification.phoneEnabled} /><span>Phone: {notification.phone}</span>
                      {window.innerWidth<=768 && <br />}<input name="emailEnabled" type="checkbox" onChange={this.toggleNotification.bind(this, i)} checked={notification.emailEnabled} /><span>E-Mail: {notification.email}</span>
                      <div className="clearfix"></div>
                      <div className="statuses">
                        <input type="checkbox" name="assigned" onChange={this.toggleNotification.bind(this, i)} checked={notification.assigned} /><span>Assigned</span>
                        <input type="checkbox" name="eta" onChange={this.toggleNotification.bind(this, i)} checked={notification.eta} /><span>ETA</span>{window.innerWidth<=768 && <br />}
                        <input type="checkbox" name="location" onChange={this.toggleNotification.bind(this, i)} checked={notification.location}/><span>On location</span>
                        <input type="checkbox" name="completed" onChange={this.toggleNotification.bind(this, i)} checked={notification.completed}/><span>Completed</span>
                        <input type="checkbox" name="rating" onChange={this.toggleNotification.bind(this, i)} checked={notification.rating}/><span>Completed + Rating</span>

                      </div>
                      <div className="clearfix"></div>
                    </div>
                  )
              })}
            </div>
            <div className="add">
              <h3>Add New:</h3>
              <div className="input notifications_input">
                <input type="text" name="name" placeholder="Name" value={this.state.newNotification.name} onChange={this.editNotification} />
                <PhoneInput
                  country="US"
                  displayInitialValueAsLocalNumber
                  placeholder="Phone"
                  value={ this.state.newNotification.phone }
                  onChange={ (value:any) => this.editPhone(value) }/>
                <input type="text" name="email" placeholder="E-Mail" value={this.state.newNotification.email}  onChange={this.editNotification} />
                <a onClick={this.addNotification.bind(this)}>Add</a>
                {this.state.task && this.state.task.client && <a onClick={this.autofill.bind(this)}>Autofill</a>}
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="status">
              <h3>Select Status:</h3>
              <div className="input">
                {this.state.selectedAll? (<label style={{cursor: "pointer"}} onClick={this.deselectAll.bind(this)}>Deselect All</label>) :
                (<label style={{cursor: "pointer"}} onClick={this.selectAll.bind(this)}>Select All</label>)}
                <input type="checkbox" name="assigned" onChange={this.editNotification} checked={this.state.newNotification.assigned}/><span>Assigned</span>
                <input type="checkbox" name="eta" onChange={this.editNotification} checked={this.state.newNotification.eta}/><span>ETA</span>
                <input type="checkbox" name="location" onChange={this.editNotification} checked={this.state.newNotification.location}/><span>On location</span>
                <input type="checkbox" name="completed" onChange={this.editNotification}checked={this.state.newNotification.completed} /><span>Completed</span>
                <input type="checkbox" name="rating" onChange={this.editNotification}checked={this.state.newNotification.rating} /><span>Completed + Rating</span>
              <div className="clearfix"></div>
              </div>
            </div>

          </div>

      </div>
    )
  }

}
