export class Provider {
  public id: number
  public name: string
  public phone: string
  public email: string
  public updated: boolean
  public qboId: number
  public syncToken: number
  public subProviders: any[]

  constructor(provider:any = {}) {
    this.id = provider.id || null
    this.name = provider.name || ""
    this.phone = provider.phone || ""
    this.email = provider.email || ""
    this.updated = true
    this.qboId = provider.qboId || null
    this.syncToken = provider.syncToken || null
    this.subProviders = (provider.subProviders && provider.subProviders.length)? provider.subProviders : []
  }
}
