import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import * as controller from '../client/controller'

export default class Clients extends React.Component<any, any> {
  searchString: any

  constructor(props: any) {
    super(props);
    this.state = {
      clients: [],
      clientsFiltered: []
    }
  }

  search() {
      let clientsFiltered = this.state.clientsFiltered
      clientsFiltered = this.state.clients.filter((client: any) => {
        const foundName = client.name?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        const foundPhone = client.phone?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        const foundEmail = client.email?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        return foundName || foundPhone || foundEmail
      })
      //each client has subClients, so search by their names too
      clientsFiltered = clientsFiltered.concat(this.state.clients.filter((client: any) => {
        return client.subClients?.some((subClient: any) => {
          const foundName = subClient.name?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          const foundPhone = subClient.phone?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          const foundEmail = subClient.email?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          return foundName || foundPhone || foundEmail

        })
      }))
      this.setState({clientsFiltered})
  }

  loadClients() {
    fetch('clients/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let clients = data.clients
        this.setState({clients, clientsFiltered: clients})
    }).catch(err => {
      //alert(err.message);
    })
  }

  componentDidMount() {

  }

  componentWillMount() {
    this.loadClients()
  }

  componentWillUnmount() {
  }


  public render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'E-Mail',
      accessor: 'email'
    }, {
      Header: 'Phone',
      accessor: 'phone'
    }, {
      id: 'settings',
      Header: 'Settings',
      sortable: false,
      maxWidth: 100,
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>
        <Link to={"/clients/" + row.value.id}><i className="fas fa-edit"></i></Link>
        <i onClick={controller.removeClient.bind(this, row.value.id, row.index)} className="fas fa-trash-alt"></i>
      </div>
    }]

    return (
      <div id="users-root">
        <input className="search" type="text" placeholder="Search client by name" onChange={this.search.bind(this)} ref={(searchString:any) => this.searchString = searchString} />

        <div className="board">
          <ReactTable
            data={this.state.clientsFiltered}
            columns={columns}
            noDataText="No clients found"
            pageSizeOptions={[100,300,500,1000]}
            defaultPageSize={100}
            style={{
              height: "calc(100vh - 120px)",
              textAlign: "center",
              fontSize: "1em",
              position: "centner",
              right: "0",
              left: "0",
              margin: "0 auto"
            }}
            className="-striped -highlight reactTable"
          />
        </div>
      </div>
    )
  }

}
