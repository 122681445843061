export class Shop {
  public id: number
  public name: string
  public phone: string
  public email: string
  public updated: boolean
  public qboId: number
  public syncToken: number

  constructor(shop:any = {}) {
    this.id = shop.id || null
    this.name = shop.name || ""
    this.phone = shop.phone || ""
    this.email = shop.email || ""
    this.updated = true
    this.qboId = shop.qboId || null
    this.syncToken = shop.syncToken || null
  }
}
