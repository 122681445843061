import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Shops from '../shops/Shops';
import ShopPage from '../shop/Shop';

export default class ShopsRouter extends React.Component<any, any> {

  public render() {
    return (
      <div id="shops-router-root">
        <div className="buttonsHeader">
          <Link to="/shops/new"><button>Add a Shop</button></Link>
          <Link to="/shops/new"><button>Export Shops</button></Link>
        </div>

        <Switch>
          <Route exact path={`/shops`} component={Shops} />
          <Route exact path={`/shops/new`} component={ShopPage} />
          <Route exact path={`/shops/:id`} component={ShopPage} />
        </Switch>

      </div>
    );
  }
}
