import * as React from 'react';
//import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Chart from 'chart.js'
import * as Moment from 'moment-timezone'
import {Task} from '../../_models/Task'
import "./Analytics.css"
const Mode = Object.freeze({"day":1, "week":7, "month":30})
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Loader = require('react-loader-spinner').default;

export default class Analytics extends React.Component<any, any> {
  tasksChart: any
  hoursChart: any
  moneyChart: any
  tasksGraph: any
  hoursGraph: any
  moneyGraph: any
  counts: any


  constructor(props: any) {
    super(props);
    this.state = {
      startDate: Moment().add(1, 'days').subtract(1, 'months').format("MM/DD/YYYY"),
      endDate: Moment().format("MM/DD/YYYY"),
      mode: Mode.day,
      loading: false,
      totalTasks: 0,
      totalHours: 0,
      totalCosts: 0,
      totalCharges: 0,
      totalProfits: 0
    }
    this.loadGraphs = this.loadGraphs.bind(this)
    this.getTasks = this.getTasks.bind(this)
  }



  componentDidMount() {
    this.getTasks()
  }

  componentWillMount() {

  }

  componentWillUnmount() {
  }

  getTasks() {
    this.setState({loading: true})
    fetch('tasks/getAllByDates', {
      method: 'POST',
      body: JSON.stringify({startDate: this.state.startDate , endDate: this.state.endDate})
    }).then(results => {
      return results.json()
    }).then(data => {
      let models = data.tasks
      this.counts = this.enumerateDaysBetweenDates()
      let totalTasks = 0
      let totalHours = 0
      let totalCosts = 0
      let totalCharges = 0
      let totalProfits = 0

      models.map((model: any) => {
        let task = new Task(model)
        let taskDate = Moment(task.date, "MM/DD/YYYY").format("MM/DD")

        let providerCost: number = 0
        let providerCharge: number = 0
        let timeWorked: number = 0

        if(task.startTime) {
          if(task.endTime) {
            let duration = Moment.utc(Moment(task.endTime, "hh:mm A").diff(Moment(task.startTime, "hh:mm A")))
            timeWorked = duration.hours() + duration.minutes()/60
          }
          providerCost = timeWorked*task.providerHourRate + task.providerFlatRate
          providerCharge = timeWorked*task.clientHourRate + task.clientFlatRate
        }
        this.counts[taskDate].tasks++
        this.counts[taskDate].hours += Math.round(timeWorked)
        this.counts[taskDate].costs += Math.round(providerCost)
        this.counts[taskDate].profits += Math.round(providerCharge - providerCost)
        totalTasks++
        totalHours += Math.round(timeWorked)
        totalCosts += Math.round(providerCost)
        totalCharges += Math.round(providerCharge)
        totalProfits += Math.round(providerCharge - providerCost)

        //TODO: profits? (also in analytics)



      })



      this.loadGraphs()
      this.setState({loading: false, totalTasks, totalHours, totalCosts, totalCharges, totalProfits})

    }).catch(err => {
      toast.error(err.message);
      this.setState({loading: false})
    })
  }


  loadGraphs() {
    let tasks:number[] = []
    let hours:number[] = []
    let costs:number[] = []
    let profits:number[] = []

    //labels
    let labels: string[] = []
    let j = 0
    for (let i=0; i<Object.keys(this.counts).length; i+=this.state.mode) {
        let labelsArray = Object.keys(this.counts).slice(i,i+this.state.mode);
        switch(this.state.mode) {
          case Mode.day:
            labels.push(labelsArray[0]);
            break;
          case Mode.week:
            labels.push(labelsArray[0] + " - " + labelsArray[labelsArray.length-1]);
            break;
          case Mode.month:
            labels.push(labelsArray[0] + " - " + labelsArray[labelsArray.length-1]);
            break;
          default:
            break;
        }

        labelsArray.map((count: string) => {
          if(tasks[j] != null) {
            tasks[j] += this.counts[count].tasks
          } else {
            tasks.push(this.counts[count].tasks)
          }
          if(hours[j] != null) {
            hours[j] += this.counts[count].hours
          } else {
            hours.push(this.counts[count].hours)
          }
          if(costs[j] != null) {
            costs[j] += this.counts[count].costs
          } else {
            costs.push(this.counts[count].costs)
          }
          if(profits[j] != null) {
            profits[j] += this.counts[count].profits
          } else {
            profits.push(this.counts[count].profits)
          }
        })
        j++
    }

    this.loadTasksChart(tasks, labels)
    this.loadHoursChart(hours, labels)
    this.loadMoneyChart(costs, profits, labels)

  }

  enumerateDaysBetweenDates() {
    var dates:any = {};

    var currDate = Moment(this.state.startDate, "MM/DD/YYYY").subtract(1, 'days').startOf('day');
    var lastDate = Moment(this.state.endDate, "MM/DD/YYYY").endOf('day');
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates[currDate.clone().format("MM/DD")] = {
          tasks: 0,
          hours: 0,
          costs: 0,
          profits: 0
        }
    }
    return dates;
  }

  loadTasksChart(tasks: number[], labels: any[]) {
    if(this.tasksChart && !this.tasksGraph) {
          let ctx = this.tasksChart.getContext('2d');
          this.tasksGraph = new Chart(ctx, {
              type: 'line',
              options: {
                responsive: false,
                title: {
                  display: true,
                  text: "Tasks"
                },
                legend: {
                  display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            //stepSize: 100
                        }
                    }]
                }

              },
              data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: "transparent",
                        borderColor: "#e13c34",
                        borderWidth: 2,
                        label: "Tasks",
                        data: tasks
                    }
                ],
            },
          });
    } else if(this.tasksChart && this.tasksGraph) {
      this.tasksGraph.options.animation = false
      this.tasksGraph.data.labels = labels
      this.tasksGraph.data.datasets[0].data = tasks
      this.tasksGraph.update()
    }
  }
  loadHoursChart(hours: number[], labels: any[]) {
    if(this.hoursChart && !this.hoursGraph) {
          let ctx = this.hoursChart.getContext('2d');
          this.hoursGraph = new Chart(ctx, {
              type: 'line',
              options: {
                title: {
                  display: true,
                  text: "Hours"
                },
                responsive: false,
                legend: {
                  display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            //stepSize: 100
                        }
                    }]
                }

              },
              data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: "transparent",
                        borderColor: "#e13c34",
                        borderWidth: 2,
                        label: "Hours",
                        data: hours
                    }
                ],
            },
          });
    } else if(this.hoursChart && this.hoursGraph) {
      this.hoursGraph.options.animation = false
      this.hoursGraph.data.labels = labels
      this.hoursGraph.data.datasets[0].data = hours
      this.hoursGraph.update()
    }
  }
  loadMoneyChart(costs: number[], profits: number[], labels: any[]) {
    if(this.moneyChart && !this.moneyGraph) {
          let ctx = this.moneyChart.getContext('2d');
          this.moneyGraph = new Chart(ctx, {
              type: 'line',
              options: {
                title: {
                  display: true,
                  text: "Costs vs Profits"
                },
                responsive: false,
                legend: {
                  display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            //stepSize: 3000
                        }
                    }]
                }

              },
              data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: "transparent",
                        borderColor: "#e13c34",
                        borderWidth: 2,
                        label: "Costs",
                        data: costs
                    },
                    {
                        backgroundColor: "transparent",
                        borderColor: "green",
                        borderWidth: 2,
                        label: "Profits",
                        data: profits
                    }
                ],
            },
          });
    }  else if(this.moneyChart && this.moneyGraph) {
      this.moneyGraph.options.animation = false
      this.moneyGraph.data.labels = labels
      //if(this.state.mode == Mode.month) this.moneyGraph.options.scales.yAxes[0].ticks.stepSize
      this.moneyGraph.data.datasets[0].data = costs
      this.moneyGraph.data.datasets[1].data = profits
      this.moneyGraph.update()
    }
  }

  handleDateChange(name:any, date: any) {
    if(name == "startDate") {
      if(Moment(date) > Moment(this.state.endDate, "MM/DD/YYYY")) {
        toast.error("Start date can't be later than end date")
        return
      }
      this.setState({[name]: Moment(date).format("MM/DD/YYYY")} , () => {this.getTasks()})
    } else {
      if(Moment(date) < Moment(this.state.startDate, "MM/DD/YYYY")) {
        toast.error("End date can't be sooner than start date")
        return
      }
      this.setState({[name]: Moment(date).format("MM/DD/YYYY")}, () => {this.getTasks()})
    }
  }


  public render() {

    return (
      <div id="analytics-root">
      {this.state.loading && <div className="loadingDiv">
        <Loader
           type="Oval"
           color="#e13c34"
           height="100"
           width="100"
        />
      </div>}
        <div className="hero">
          <div className="dates">
          <DatePicker
            selected={Moment(this.state.startDate, "MM/DD/YYYY").toDate()}
            onChange={this.handleDateChange.bind(this, "startDate")}
            autoComplete="disable_autocomplete"
          />
          <span>-</span>
          <DatePicker
            selected={Moment(this.state.endDate, "MM/DD/YYYY").toDate()}
            onChange={this.handleDateChange.bind(this, "endDate")}
            autoComplete="disable_autocomplete"
          />
          </div>
          <h2><span>{this.state.totalTasks}</span>Tasks</h2>
          <h2><span>{this.state.totalHours}</span>Hours</h2>
          <h2><span>${this.state.totalCosts}</span>Paid Provider</h2>
          <h2><span>${this.state.totalCharges}</span>Charged Client</h2>
          <h2><span>${this.state.totalProfits}</span>Profits</h2>
        </div>
        <div className="buttons right">
          <button onClick={() => {this.setState({mode: Mode.day}, () => {this.loadGraphs()})}}>Day view</button>
          <button onClick={() => {this.setState({mode: Mode.week}, () => {this.loadGraphs()})}}>Week view</button>
          <button onClick={() => {this.setState({mode: Mode.month}, () => {this.loadGraphs()})}}>Month view</button>
        </div>
        <div className="buttons left">
          <button onClick={() => {this.setState({startDate: Moment().add(1, 'days').subtract(1, 'weeks').format("MM/DD/YYYY")}, () => {this.getTasks()})}}>Last week</button>
          <button onClick={() => {this.setState({startDate: Moment().add(1, 'days').subtract(1, 'months').format("MM/DD/YYYY")}, () => {this.getTasks()})}}>Last month</button>
          <button onClick={() => {this.setState({startDate: Moment().add(1, 'days').subtract(1, 'years').format("MM/DD/YYYY")}, () => {this.getTasks()})}}>Last year</button>
        </div>
        <div className="charts">
          <canvas id="tasksChart" className="chart" ref={(elem:any) => {this.tasksChart = elem}}></canvas>
          <canvas id="hoursChart" className="chart" ref={(elem:any) => {this.hoursChart = elem}}></canvas>
          <canvas id="moneyChart" className="chart" ref={(elem:any) => {this.moneyChart = elem}}></canvas>
        </div>
      </div>
    )
  }

}
