export class Address {
  public id: number
  public name: string
  public address: string
  public type: string
  public updated: boolean

  constructor(address:any = {}) {
    this.id = address.id || null
    this.name = address.name || ""
    this.address = address.address || ""
    this.type = address.type || "pickup"

    this.updated = true
  }
}
