import * as React from "react";
import "./popup.css";

export class Rates extends React.Component<any, any> {
  closePopup: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loadedTask: null,
    };
    this.closePopup = this.props.closePopup;
  }

  componentDidMount() {
    this.setState({
      loadedTask: this.props.task,
    });
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      loadedTask: nextProps.task,
    });
  }

  updateRates(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let task = this.state.loadedTask;
    task[name] = value;
    task.updated = false;
    this.setState({ loadedTask: task });
  }

  public render() {
    let task = this.state.loadedTask;
    return (
      <div id="popup-root">
        <i onClick={this.closePopup} className="fas fa-times"></i>

        <div className="container">
          {task && (
            <div>
              <h2>Set Rates:</h2>
              <div className="input">
                <label>Client:</label>
                <div className="right ratesC">
                  <input
                    type="text"
                    name="clientHourRate"
                    placeholder="Hourly"
                    onChange={this.updateRates.bind(this)}
                    value={task.clientHourRate ? task.clientHourRate : ""}
                  />
                  <input
                    type="text"
                    name="clientFlatRate"
                    placeholder="Flat"
                    onChange={this.updateRates.bind(this)}
                    value={task.clientFlatRate ? task.clientFlatRate : ""}
                  />
                  <input
                    type="text"
                    name="clientTolls"
                    placeholder="Tolls"
                    onChange={this.updateRates.bind(this)}
                    value={task.clientTolls ? task.clientTolls : ""}
                  />
                  <input
                    type="text"
                    name="clientTip"
                    placeholder="Tip"
                    onChange={this.updateRates.bind(this)}
                    value={task.clientTip ? task.clientTip : ""}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="input">
                <label>Provider:</label>
                <div className="right ratesC">
                  <input
                    type="text"
                    name="providerHourRate"
                    placeholder="Hourly"
                    onChange={this.updateRates.bind(this)}
                    value={task.providerHourRate ? task.providerHourRate : ""}
                  />
                  <input
                    type="text"
                    name="providerFlatRate"
                    placeholder="Flat"
                    onChange={this.updateRates.bind(this)}
                    value={task.providerFlatRate ? task.providerFlatRate : ""}
                  />
                  <input
                    type="text"
                    name="providerTolls"
                    placeholder="Tolls"
                    onChange={this.updateRates.bind(this)}
                    value={task.providerTolls ? task.providerTolls : ""}
                  />
                  <input
                    type="text"
                    name="providerTip"
                    placeholder="Tip"
                    onChange={this.updateRates.bind(this)}
                    value={task.providerTip ? task.providerTip : ""}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
