import * as React from 'react';
import { Link } from 'react-router-dom';
const Loader = require('react-loader-spinner').default;
import { toast } from 'react-toastify';
import {Address} from '../../_models/Address'

export default class AddressPage extends React.Component<any, any> {
  address: any
  constructor(props: any) {
    super(props);
    this.state = {
      oldName: null,
      oldAddress: null,
      address: new Address(),
      updating: false
    }
  }



  componentDidMount() {
    if(this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadAddress(this.props.match.params.id)
    }
    let autocomplete = new window.google.maps.places.Autocomplete(this.address)
    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace()
        let address = this.state.address
        address.address = place.formatted_address
        address.updated = false
        this.setState({address})
    });
  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.address.id != nextProps.match.params.id) {
      this.loadAddress(nextProps.match.params.id)
    } else {
      this.setState({address: new Address()})
    }

  }

  nameChange(event: any) {
    let address = this.state.address
    address.name = event.target.value
    address.updated = false
    this.setState({address})
  }


  updateAddress(event:any) {
    event.preventDefault()
    this.setState({updating: true})
    fetch('tasks/editAddress', {
      method: 'POST',
      body: JSON.stringify(Object.assign({oldName: this.state.oldName, oldAddress: this.state.oldAddress}, this.state.address))
    }).then(results => {
      return results.json();
    }).then(data => {
        let address = this.state.address
        address.updated = true
        toast.success("Address updated successfuly")
        this.setState({address, updating: false, oldName: address.name, oldAddress: address.address})
    }).catch(err => {
      toast.error(err.message);
      this.setState({updating: false})
    })


  }



  loadAddress(id:any) {
    fetch('tasks/getAddress', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let address = new Address(data.address)
        this.setState({address, oldName: address.name, oldAddress: address.address})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  public render() {
    let address = this.state.address
    return (
      <div id="edit-account-root">
          {this.state.updating && <div className="loadingDiv">
            <Loader
               type="Oval"
               color="#e13c34"
               height="100"
               width="100"
            />
          </div>}
          <Link to="/addresses"><i className="fas fa-times"></i></Link>
          <form className="data">
            <input className="third" onChange={this.nameChange.bind(this)} defaultValue={address.name} name="name" placeholder="Name" />
            <input className="third" ref={(ref:any) => this.address = ref} defaultValue={address.address} name="address" placeholder="Address" />

            <button className={"update "+((address.id && address.updated)? "updated" : "")} onClick={this.updateAddress.bind(this)} disabled={address.updated || this.state.updating}>
              {this.state.updating? "Updating" :
                address.id? address.updated? "Updated" :
                "Update" :
                "Save"}
            </button>
          </form>
        </div>
    )
  }
}
