import * as React from 'react';
import * as Moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import {ClientTask} from '../../../_models/ClientTask'
var Timekeeper = require('react-timekeeper').default;
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import * as controller from '../controller'
import { toast } from 'react-toastify';
const Loader = require('react-loader-spinner').default;
const ModalImage = require('react-modal-image').default

import '../TaskData.css';

export default class ClientData extends React.Component<any, any> {
  map: any;
  directionsDisplay: any;
  directionsService: any;
  startTime: any;
  endTime: any;
  loadTask: any
  calculateAndDisplayRoute: any
  loadMap: any
  address: any
  loadTaskFromToken: any
  clientUrl: any
  marker: any
  changeAddress: any
  activeIndex: number

  constructor(props: any) {
    super(props);
    this.state = {
      task: null,
      clientTask: null,
      startTimeClicked: false,
      endTimeClicked: false,
      locationsTimes: [],
      addressPopupOpened: false,
      clientView: false,
      updating: false,
      clientUrl: null
    }
    this.loadTask = controller.loadTask.bind(this)
    this.calculateAndDisplayRoute = controller.calculateAndDisplayRoute.bind(this)
    this.loadMap = controller.loadMap.bind(this)
    this.loadTaskFromToken = controller.loadTaskFromToken.bind(this)
    this.changeAddress = controller.changeAddress.bind(this)


  }


  componentDidMount() {
    //Google Maps
    this.loadMap(false)
    if(this.props.match.path == "/track/client/:token") {
      this.setState({clientView: true})
    }
  }


  removeLocation(index: number) {
    if(this.state.clientView) return
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    let clientTask = this.state.clientTask

    if(clientTask.locations[index].id) {
      fetch('tasks/client/deleteLocation', {
        method: 'POST',
        body: JSON.stringify({id: clientTask.locations[index].id})
      }).catch(err => {
        toast.error(err.message);
      })
    }

    clientTask.locations.splice(index,1)
    this.setState({clientTask}, () => {
      this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, this.state.clientTask);
    })
  }

  removeSignature(index: number) {
    if(this.state.clientView) return
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    let clientTask = this.state.clientTask
    if(clientTask.signatures[index].id) {
      fetch('tasks/client/deleteSignature', {
        method: 'POST',
        body: JSON.stringify({id: clientTask.signatures[index].id})
      }).catch(err => {
        toast.error(err.message);
      })
    }

    clientTask.signatures.splice(index,1)
    this.setState({clientTask})
  }


  saveClientData() {
    if(this.state.clientView) return
    let clientTask = this.state.clientTask
    this.setState({savingTask: true, updating: true})
    fetch('tasks/client/edit', {
      method: 'POST',
      body: JSON.stringify(Object.assign(clientTask, {taskId: this.state.task.id}))
    }).then(results => {
      return results.json();
    }).then(data => {
      let clientTask = new ClientTask(data.clientTask)
      let clientUrl = data.url
      this.setState({clientTask, savingTask: false, updating: false, clientUrl}, () => {
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, clientTask)
      })
    }).catch(err => {
      this.setState({savingTask: false, updating: false})
      toast.error(err.message);
    })
  }

  handleDateChange(date:any) {
    if(this.state.clientView) return
    let clientTask = this.state.clientTask
    clientTask.date = date.format("MM/DD/YYYY")
    this.setState({clientTask})
  }

  closeTimepickers() {
    if(this.state.clientView) return
    this.setState({
      startTimeClicked: false,
      endTimeClicked: false,
      locationsTimes: []
    })
  }

  handleTaskChange(event: any) {
    if(this.state.clientView) return
    let clientTask = this.state.clientTask
    clientTask[event.target.name] = event.target.value
    this.setState({clientTask});
  }

  handleStartTimeChange(newTime:any) {
    if(this.state.clientView) return
    this.startTime.value = newTime.formatted.toUpperCase()
    let clientTask = this.state.clientTask
    clientTask.startTime = newTime.formatted
    this.setState({clientTask})
  }

  handleEndTimeChange(newTime:any) {
    if(this.state.clientView) return
    this.endTime.value = newTime.formatted.toUpperCase()
    let clientTask = this.state.clientTask
    clientTask.endTime = newTime.formatted
    this.setState({clientTask})
  }

  handleLocationTimeChange(index: number, newTime: any) {
    if(this.state.clientView) return
    let clientTask = this.state.clientTask
    let date = Moment.tz(clientTask.locations[index].time, process.env.REACT_APP_TIMEZONE || "")
    let h = newTime.formatted24.split(":")[0]
    let m = newTime.formatted24.split(":")[1]
    date.set({h,m})
    clientTask.locations[index].time = date.format()
    this.setState({clientTask})
  }

  toogleLayouts() {
    if(this.state.clientView) return
    let clientTask = this.state.clientTask
    clientTask.layouts = !clientTask.layouts
    this.setState({clientTask})
  }

  copyToClipboard(event:any) {
    this.clientUrl.select();
    document.execCommand('copy');
    toast.success("URL copied")
  }

  addLocation() {
    let clientTask = this.state.clientTask
    clientTask.locations.push({type: "location", time: new Date(), address: ""})
    this.setState({clientTask}, () => {
      this.changeAddress(clientTask.locations.length-1, "clientTask")
    })
  }

  changeLocType(event: any) {
    let clientTask = this.state.clientTask
    clientTask.locations[this.activeIndex].type = event.target.value
    this.setState({clientTask})
  }

  public render() {
    let task = this.state.clientTask
    return (
      <div id="task-data-root" className={this.state.clientView? "clientView" : ""}>
        {this.state.updating && <div className="loadingDiv">
          <Loader
             type="Oval"
             color="#e13c34"
             height="100"
             width="100"
          />
        </div>}
        {task && this.state.addressPopupOpened && <div className="addressPopup">
          <i onClick={() => {this.setState({addressPopupOpened: false})}} className="fas fa-times"></i>
          <select value={task.locations[this.activeIndex].type} onChange={this.changeLocType.bind(this)}>
            <option value="start">Start Clock</option>
            <option value="eta">Pinned at ETA</option>
            <option value="location">On Location</option>
            <option value="completed">Completed</option>
            <option value="stop">Stop Clock</option>
          </select>
          <input type="text" id="address" ref={(ref:any) => this.address = ref} placeholder="Address" />
        </div>}
        {this.state.task && <Link to={this.props.match.path.replace(":id/data/client", this.state.task.id+ this.props.location.search)}><i className="fas fa-times"></i></Link>}
        <div className="clearfix"></div>
        <div id="client-data-root" className="container">
          {task && <div>
            <h3>Task Summery:</h3>
            <div className="summery">
              <span className="info"><strong>Date:</strong>
              <DatePicker
                selected={Moment(task.date, "MM/DD/YYYY").toDate()}
                onChange={this.handleDateChange.bind(this)}
                name="date"
                className="click"
                readOnly={this.state.clientView || false}
              />
              </span>

              <span className="info"><strong>Start Time:</strong>

              <input readOnly className="click" type="text" name="startTime"
              ref={el => this.startTime = el} placeholder="Start Time"
              onClick={() => {if(this.state.clientView) return; this.setState({startTimeClicked: true, endTimeClicked: false, locationsTimes: []})}}
              onChange={this.handleTaskChange} defaultValue={task.startTime}/>

              {this.state.startTimeClicked && <Timekeeper onChange={this.handleStartTimeChange.bind(this)}
                            onDoneClick={this.closeTimepickers.bind(this)}
                            time={task.startTime}
                            switchToMinuteOnHourSelect={true}
                            closeOnMinuteSelect={true} />}

              </span>
              <span className="info"><strong>End Time:</strong>

              <input readOnly className="click" type="text" name="endTime"
              ref={el => this.endTime = el} placeholder="End Time" onChange={this.handleTaskChange}
              onClick={() => {if(this.state.clientView) return; this.setState({endTimeClicked: true, startTimeClicked: false, locationsTimes: []})}}
              defaultValue={task.endTime}/>

              {this.state.endTimeClicked && <Timekeeper onChange={this.handleEndTimeChange.bind(this)}
                            onDoneClick={this.closeTimepickers.bind(this)}
                            time={task.endTime}
                            switchToMinuteOnHourSelect={true}
                            closeOnMinuteSelect={true} />}

              </span>
              <span className="info"><strong>Duration:</strong> {task.endTime && Moment.utc(Moment(task.endTime, "hh:mm A").diff(
                Moment(task.startTime, "hh:mm A"))).format("HH:mm")}</span>
              <span className="info"><strong>Layouts:</strong> <span className="red click" onClick={this.toogleLayouts.bind(this)}>{task.layouts? "Yes" : "No"}</span></span>
            </div>
            <div className="data">
              <ul className="box">
                <h3>Provider Data:</h3>
                {task.locations.map((location: any, i:number) => {
                  let text
                  if(location.type == "start") {
                    text = "Start Clock"
                  } else if (location.type == "completed"){
                    text = "Completed"
                  } else if (location.type == "eta"){
                    text = "Pinned at ETA"
                  } else if(location.type == "location") {
                    text = "On Location"
                  } else if(location.type == "stop") {
                    text = "Stopped Clock"
                  } else if(location.type == "pause") {
                    text = "Paused"
                  } else if(location.type == "unpause") {
                    text = "Unpaused"
                  }
                  let time = Moment.tz(location.time, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A")
                  return (<li className="location" key={i}>
                  {!this.state.clientView && <i onClick={this.removeLocation.bind(this, i)} className="fa fa-times"></i>}
                  <i className="number">{String.fromCharCode(97 + i).toUpperCase()}</i>
                  <strong>{text}:</strong>
                  <span onClick={() => {
                    this.setState({locationsTimes: []}, () => {
                      if(this.state.clientView) return;
                      this.state.locationsTimes[i] = true
                      this.setState({locationsTimes: this.state.locationsTimes})
                    })
                  }} >{time}</span>

                  <h6 onClick={controller.changeAddress.bind(this, i, "clientTask")}>{location.address}</h6>

                  {this.state.locationsTimes[i] && <Timekeeper onChange={this.handleLocationTimeChange.bind(this, i)}
                                onDoneClick={() => {this.setState({locationsTimes: []})}}
                                time={time}
                                switchToMinuteOnHourSelect={true}
                                closeOnMinuteSelect={true} />}</li>)
                })}
                <h4 className="addLocation" onClick={this.addLocation.bind(this)}>+ Add location</h4>

              </ul>

            </div>
            </div>}
            <div id="map"></div>

            {task && <div>
              <div className="clearfix"></div>
              <ul className="box">
                <h3>Signatures:</h3>
                {task.signatures.map((signature: any, i: number) => {
                  let taskId = this.state.task? this.state.task.id : task.taskId
                  return (<li className="signature" key={i}>
                    {!this.state.clientView && <i onClick={this.removeSignature.bind(this, i)} className="fa fa-times"></i>}
                    <strong>{signature.name}:</strong>
                    <div className="sigData" style={{backgroundImage: 'url('+process.env.REACT_APP_AWS+"/tasks/"+taskId+"/signatures/"+signature.data+')'}}></div>
                  </li>)
                })}
              </ul>

              <div className="clearfix"></div>
              <ul className="box imageBox">
                <h3>Images & Receipts:</h3>

                {task.expenses.map((expense: any, i: number) => {
                  let taskId = this.state.task? this.state.task.id : task.taskId
                  let expenseUrl = process.env.REACT_APP_AWS+"/tasks/"+taskId+"/expenses/"+expense.image
                  return (<li className="expense" key={i}>
                    {expense.name && expense.name.length > 0 && <strong>{expense.name}:</strong>}
                    <ModalImage
                      key={i}
                      small={expenseUrl}
                      large={expenseUrl}
                    />
                  </li>)
                })}

                {task.images.map((image: any, i: number) => {
                  let taskId = this.state.task? this.state.task.id : task.taskId
                  let imageUrl = process.env.REACT_APP_AWS+"/tasks/"+taskId+"/images/"+image.url
                  return (<li className="image" key={i}>
                    <ModalImage
                      key={i}
                      small={imageUrl}
                      large={imageUrl}
                    />
                  </li>)
                })}

              </ul>

            </div>}
            {!this.state.clientView && <div className="buttons">
              {this.state.task && <Link to={this.props.match.path.replace(":id/data/client", this.state.task.id+"/data/office"+ this.props.location.search)}><button className="primary-btn">Back to Office Data</button></Link>}
              <button onClick={this.saveClientData.bind(this)} className="primary-btn green">Save Changes</button>
            </div>}

            <div className="clearfix"></div>

            {this.state.clientUrl && <input className="clientUrl" ref={(el) => this.clientUrl = el} value={this.state.clientUrl} readOnly onClick={this.copyToClipboard.bind(this)}/>}

            </div>

      </div>
    )
  }

}
