import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Task } from "../../_models/Task";
import carIcons from "./carIcons";
var _ = require('lodash');

type TMarr = {
  taskId: number;
  marker: any;
  softTimerId: any;
  driving: boolean;
  speed: number;
};

const google = window.google;
//using at first load, to show all active cars
const softOfflineAfterMs = 5 * 60 *  1000; //5min
const moveMarkerTime = 1000;
const fps = 50;

const ActiveProvidersMap = (props: any) => {
  const { socket } = props;
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState<any>(null);
  const [markers, setMarkers] = useState<Record<string | number, TMarr> | null>(null);
  const [listener, setListener] = useState<any>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState(0);
  const ref = useRef<any>();
  ref.current = { markers, tasks, infoWindow, map, selectedTask };

  const markerLocks = new Set(); // Add this line to create a locking mechanism

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const moveMarkerSmoothly = async (
    marker: any,
    sLat: number,
    sLng: number,
    eLat: number,
    eLng: number,
    carAngle: number
  ) => {
    const latStep = (eLat - sLat) / fps;
    const lngStep = (eLng - sLng) / fps;
    for (let i = 0; i < fps; i++) {
      await delay(moveMarkerTime / fps);
      marker.setPosition({
        lat: sLat + latStep * i,
        lng: sLng + lngStep * i,
      });
      marker.setIcon(_getMarkerIcon(carAngle));
    }
  };

  const _getMarkerIcon = (carAngle: number, isOfflineSoft?: boolean) => {
    return {
      url: carIcons.svg(carAngle - 90, isOfflineSoft ? "black" : "red"),
      scaledSize: new window.google.maps.Size(25, 25), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(10, 10), // anchor
      labelOrigin: new google.maps.Point(25, -11),
    };
  };

  const setMarkerOffline = (markerObj: TMarr) => {
    console.log("setMarkerOffline ",`Task ${markerObj.taskId}`,markerObj);
    markerObj.marker.setIcon(_getMarkerIcon(0, true));
    markerObj.marker.setLabel({
      text: `off`,
      color: "black",
      fontWeight: "normal",
      fontSize: "16px",
      top: "10px",
    });
  };

  const handleGeoUpdate = async (
    data: {
      carAngle: number;
      taskIds: number[];
      lat: number;
      lng: number;
      velocity: number;
    }
  ) => {
    const { markers, map, selectedTask } = ref.current;
    if(!map) return;
    const { taskIds, lat, lng } = data || {};
    let { velocity, carAngle } = data || {};
    if (typeof velocity !== "number" || velocity < 0 || isNaN(Number(velocity)))
      velocity = 0;
    if (isNaN(Number(carAngle))) carAngle = 90; //default angle
    if (taskIds?.length && lat && lng) {
      // console.log(markers);
      let newMarkers = { ...markers };
      taskIds.forEach(async (taskId) => {
        // console.log("newMarkers", newMarkers);
        console.log("handleGeoUpdate Start", `Task ID: ${taskId}`);

        if (markerLocks.has(taskId)) { // Check if the taskId is locked
          console.log(`Task ID ${taskId} is locked, skipping update`);
          return;
        }
        
        if (!newMarkers[taskId]) {
          markerLocks.add(taskId); // Lock the taskId
          newMarkers = { ...newMarkers, [taskId]: addMarker({taskId, lat, lng, carAngle, velocity})}
          markerLocks.delete(taskId); // Unlock the taskId
        } else {
          //marker found, edit marker
          clearTimeout(newMarkers[taskId].softTimerId);
          if(selectedTask && selectedTask !== taskId) {
            newMarkers[taskId].marker.setOpacity(0)
          } else {
            newMarkers[taskId].marker.setOpacity(1)
          }
          console.log("editing markers for ", taskId);
          await moveMarkerSmoothly(
            newMarkers[taskId].marker,
            markers[taskId].marker.position.lat(),
            markers[taskId].marker.position.lng(),
            lat,
            lng,
            carAngle
          );
          
          newMarkers[taskId].driving = true;
          newMarkers[taskId].speed = Math.floor(velocity * 1.9)
          newMarkers[taskId].marker.setLabel({
            text: `${Math.floor(velocity * 1.9)} mph`,
            color: "black",
            fontWeight: "bold",
            fontSize: "16px",
            top: "10px",
          });
          newMarkers[taskId].softTimerId = setTimeout(
            () => setMarkerOffline(newMarkers[taskId]),
            softOfflineAfterMs
          );
        }
      });
      setMarkers(newMarkers);
      console.log("handleGeoUpdate End", `Task IDs: ${taskIds}`);
    }
  };

  const handleTaskUpdate = (data: any) => {
    const changedTask = new Task(data.task);
    if (changedTask.status !== "active") return handleTaskDelete(changedTask);
    console.log(`handleTaskUpdate ${changedTask.id}`, changedTask)
    const { tasks }: { tasks: Task[] } = ref.current;
    let taskExisted = false;
    const newTasks = tasks.map((task) => {
      if (task.id === changedTask.id) {
        taskExisted = true;
        return changedTask;
      }
      return task;
    });
    if (!taskExisted) {
      newTasks.push(changedTask);
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (data: any) => {
    console.log(`handleTaskDelete ${data.id}`, data)
    const { tasks, markers }: { tasks: Task[]; markers: any } = ref.current;
    const newTasks = tasks.filter((task) => task.id !== data.id);
    if (markers[data?.id]) {
      setMarkerOffline(markers[data?.id]);
    }
    setTasks(newTasks);
  };

  // const handleProviderDisconnect = ({ providerId }: { providerId: number }) => {
  //   //remove tasks of that provider
  //   const { tasks, markers }: { tasks: Task[]; markers: any } = ref.current;
  //   for (const task of tasks) {
  //     if (
  //       (Number(task.provider?.id) === Number(providerId) ||
  //         Number(task.subProvider?.id) === Number(providerId)) &&
  //       markers[task?.id]
  //     ) {
  //       setMarkerOffline(markers[task?.id], true);
  //     }
  //   }
  // };

  useEffect(() => {
    socket.on("task_delete", handleTaskDelete);
    socket.on("provider_update", handleTaskUpdate);
    // socket.on("provider_disconnect", handleProviderDisconnect);
    return () => {
      socket.off("taskGeos_update");
      socket.off("task_delete");
      socket.off("provider_update");
      // socket.off("provider_disconnect");
    };
  }, []);

  useEffect(() => {
    fetch("tasks/getAllActiveMap", {
      method: "POST",
      body: JSON.stringify({ archived: false }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let tasks: Task[] = [];
        data.tasks.map((task: any) => {
          task = new Task(task);
          tasks.push(task);
        });
        setTasks(tasks);
        setMap(
          new google.maps.Map(document.getElementById("activeProvidersMap"), {
            zoom: 8,
            mapTypeControl: false,
            streetViewControl: false,
            center: { lat: 40.712776, lng: -74.005974 }, //New York Center
          })
        );
        setInfoWindow(
          new google.maps.InfoWindow({
            content: "",
          })
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);


  useEffect(() => {
    if (!listener && markers) {
      setListener(true)
      console.log("Markers set, adding socket event", markers)
      const listenFunction = _.debounce(handleGeoUpdate, 200)
      socket.on("taskGeos_update", listenFunction);
    }
  }, [markers]);

  useEffect(() => {
    if(map && infoWindow) {
      console.log("Loaded map")
      const {markers} = ref.current
      let newMarkers = {...markers}
      tasks.map((task: Task) => {
        if(task.taskGeos && task.id) {
          const lastLocation = task.taskGeos[0];
          const taskId:number = task.id
          if(!lastLocation) return;
          markerLocks.add(taskId);
          newMarkers = {...newMarkers, 
            [taskId]: addMarker({taskId,lat: lastLocation.lat, lng: lastLocation.lng, carAngle: 90, velocity: 0, isOfflineSoft: true})
          }
          markerLocks.delete(taskId);
        }
      })
      setMarkers(newMarkers)
    }
  }, [map, tasks, infoWindow])

  const getMarkerTaskString = (taskId: number) => {
    const {markers} = ref.current
    const { tasks }: { tasks: Task[] } = ref.current;
    const task: any = tasks.find((task) => task.id === taskId) || {};
    const {
      id,
      po,
      date,
      startTime,
      provider = { name: "" },
      subProvider = { name: "" },
      client = { name: "" },
      subClient = { name: "" },
      taskGeos,
    } = task;


    const driving = markers[taskId]?.driving
    const speed = markers[taskId]?.speed
    console.log("Driving?", driving, markers[taskId])

    return `<div style="font-size: 15px;">
<h2 style="text-align: center;margin-bottom: 5px"><strong><a href="/tasks/${id}" target="_blank">${id}: ${po} </a></strong></h2>
<div style="margin-bottom: 5px"><strong style="font-weight: bold;margin-right: 5px;">Date:</strong> ${date} - ${startTime}</div>
<div style="margin-bottom: 5px"><strong style="font-weight: bold;margin-right: 5px;">Client:</strong>${
      client?.name + (subClient ? " | " + subClient?.name : "")
    }</div>
<div style="margin-bottom: 5px"><strong style="font-weight: bold;margin-right: 5px;">Provider:</strong>${
      provider?.name + (subProvider ? " | " + subProvider?.name : "")
    }</div>
    ${!driving && taskGeos && taskGeos.length > 0 ? `<div style="margin-bottom: 5px"><strong style="font-weight: bold;margin-right: 5px;">Last Location Update:</strong>${taskGeos[0].createdAt}</div>` : ""}
    ${driving? `<div style="margin-bottom: 5px"><strong style="font-weight: bold;margin-right: 5px;">Speed:</strong>${speed} mph</div>` : ""}

</div>`;
  };

  const isTaskDataCorrect = (taskId: number) => {
    const { tasks }: { tasks: Task[] } = ref.current;
    const task: any = tasks.find((task) => task.id === taskId) || {};
    return task?.id;
  };

  const addMarker = ({taskId, lat, lng, carAngle, velocity, isOfflineSoft} : any) => {
          if (!isTaskDataCorrect(taskId)) return;
          console.log(`Adding marker for ${taskId}`)
          const { infoWindow, selectedTask } = ref.current;
          //marker not found, create marker
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            icon: _getMarkerIcon(carAngle, isOfflineSoft),
            title: `task id - ${taskId}`,
            opacity: selectedTask && selectedTask !== taskId? 0 : 1,
            label: {
              text: isOfflineSoft? "off" : `${Math.floor(velocity * 1.9)} mph`,
              color: "black",
              fontWeight: "bold",
              fontSize: "16px",
              top: "10px",
            },
            map,
          });
          marker.addListener("click", () => {
            infoWindow.setContent(getMarkerTaskString(taskId));
            infoWindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });
          const markerObj: TMarr = {
            marker,
            taskId,
            softTimerId: null,
            driving: !isOfflineSoft,
            speed: Math.floor(velocity * 1.9)
          };

          
          markerObj.softTimerId = setTimeout(
            () => setMarkerOffline(markerObj),
            softOfflineAfterMs
          );
          return markerObj
  }



  return <div style={{ height: "100vh" }}>
    <select value={selectedTask} style={{zIndex: 100, position: "absolute", bottom: "10px", left: "10px", width: "250px"}} onChange={(e:any) => {
      const taskId = parseInt(e.target.value) || 0
      console.log("Setting", taskId)
      setSelectedTask(taskId)
      const {markers} = ref.current
      let newMarkers = {...markers}
      if(taskId) {
        Object.keys(newMarkers).map((key) => {
          if(taskId !== parseInt(key)) {
            newMarkers[key].marker?.setOpacity(0);
          } else {
            newMarkers[key].marker?.setOpacity(1);
          }
        })
      } else {
        Object.keys(newMarkers).map((key) => {
          newMarkers[key].marker?.setOpacity(1);
        })
      }
      setMarkers(newMarkers);
    }}>
      <option selected>All Tasks</option>
      {tasks.map((task:Task) => {
        return <option value={task.id}>{task.id} : {task.po}</option>
      })}
    </select>
    <div style={{ height: "100vh" }} id="activeProvidersMap" /> 
  </div>;
};

export default React.memo(ActiveProvidersMap);
