import * as React from 'react';

export default class Chat extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
    }
  }


  componentDidMount() {

  }

  componentWillMount() {

  }

  componentWillUnmount() {
  }


  public render() {
    return (
      <h1 style={{textAlign: "center", padding: "60px", fontSize: "2em"}}>Coming Soon</h1>
    )
  }

}
