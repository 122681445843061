var methods =  [
    {
      "value": 243,
      "label": "Amex 39009"
    },
    {
      "value": 134,
      "label": "Chase Card"
    },
    {
      "value": 185,
      "label": "Chase Ink 7131 SE"
    },
    {
      "value": 184,
      "label": "Chase Ink 7214 SH"
    },
    {
      "value": 178,
      "label": "Chase Ink 7251 MK"
    },
    {
      "value": 202,
      "label": "Chase Ink 7289 AJ"
    },
    {
      "value": 182,
      "label": "Chase Ink 8188 CK"
    },
    {
      "value": 201,
      "label": "Chase Ink 8212 EL"
    },
    {
      "value": 180,
      "label": "Chase Ink 8238 MS"
    },
    {
      "value": 179,
      "label": "Chase Ink 8246 EW"
    },
    {
      "value": 181,
      "label": "Visa Yiddy 8188"
    },
    {
      "value": 187,
      "label": "SPG New Old 1001 - New 1000"
    }
  ]

module.exports = methods;
