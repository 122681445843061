import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Cars from '../cars/Cars';
import CarPage from '../car/Car';

export default class CarsRouter extends React.Component<any, any> {

  public render() {
    return (
      <div id="cars-router-root">
        <div className="buttonsHeader">
          <Link to="/cars/new"><button>Add a Vehicle</button></Link>
          <Link to="/cars/new"><button>Export Vehicles</button></Link>
        </div>

        <Switch>
          <Route exact path={`/cars`} component={Cars} />
          <Route exact path={`/cars/new`} component={CarPage} />
          <Route exact path={`/cars/:id`} component={CarPage} />
        </Switch>

      </div>
    );
  }
}
