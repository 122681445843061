export function themeStyles(theme: any) {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
    ...theme.colors,
      primary25: '#f39a96',
      primary50: '#e13c34',
      primary: '#e13c34',
      neutral0: '#242424',
    }
  }
}

export const customSelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: "10px 20px",
    width: "100%",
    fontSize: "0.8em",
    color: "#252c3a",
    cursor: "pointer"
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#252c3a"
  }),
  container: (provided: any) => ({
    ...provided,
      width: "100%",
      position: "relative",
      background: "#fff"
  }),
  control: (provided: any) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    width: "100%",
    height: "100%",
    margin: 0,
    backgroundColor: "none",
    position: "relative",
    float: "none",
    borderRadius: "0",
    border: "none"
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#252c3a",
    fontWeight: "normal",
    paddingLeft: "12px",
    width: "100%",
    cursor: "pointer",
    fontSize: "0.9em"
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    marginLeft: 0,
    marginTop: 0,
    border: 0,
    borderRadius: 0,
    backgroundColor: "#fff",
    overflowY: "scroll",
    cursor: "pointer"
  }),
  singleValue: (provided:any) => ({
    ...provided,
    color: "#252c3a",
    fontWeight: "normal",
    paddingLeft: "12px",
    width: "100%",
    cursor: "pointer",
    fontSize: "0.9em"
  })
}
