import * as React from 'react';
//import Select from 'react-select';
import { Link } from 'react-router-dom';
import {Provider} from '../../_models/Provider'
//import {customSelectStyles} from './selectStyles.js'
import * as controller from './controller'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const Loader = require('react-loader-spinner').default;
import { toast } from 'react-toastify';

export default class ProviderPage extends React.Component<any, any> {

  handleProviderChange: any
  handleSelectChange: any
  updateProvider:any
  editPhone: any
  editSubPhone: any
  constructor(props: any) {
    super(props);
    this.state = {
      provider: new Provider(),
      updating: false
    }
    this.updateProvider = controller.updateProvider.bind(this)
    this.handleProviderChange = controller.handleProviderChange.bind(this)
    this.editPhone = controller.editPhone.bind(this)
    this.editSubPhone = controller.editSubPhone.bind(this)

  }



  componentDidMount() {
    if(this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadProvider(this.props.match.params.id)
    }

  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.provider.id != nextProps.match.params.id) {
      this.loadProvider(nextProps.match.params.id)
    } else {
      this.setState({provider: new Provider()})
    }

  }



  loadProvider(id:any) {
    fetch('providers/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let provider = new Provider(data.provider)
        this.setState({provider})
    }).catch(err => {
      toast.error(err.message);
    })
  }

  addSubProvider(event:any) {
    event.preventDefault()
    let provider = this.state.provider
    provider.subProviders.push({name: "", email: "", phone: ""})
    provider.updated = false
    this.setState({provider})
  }


  public render() {
    let provider = this.state.provider
    return (
      <div id="edit-account-root">
          {this.state.updating && <div className="loadingDiv">
            <Loader
               type="Oval"
               color="#e13c34"
               height="100"
               width="100"
            />
          </div>}
          <Link to="/providers"><i className="fas fa-times"></i></Link>
          <form className="data">
            <input className="third" onChange={this.handleProviderChange} defaultValue={provider.name} name="name" placeholder="Provider's Name" />
            <PhoneInput
              className="third"
              country="US"
              displayInitialValueAsLocalNumber
              placeholder="Provider's Phone"
              value={ provider.phone }
              onChange={ (value:any) => this.editPhone(value) }/>
            <input className="third" onChange={this.handleProviderChange} defaultValue={provider.email} name="email" placeholder="Provider's E-Mail" />
            <div className="clearfix"></div>

            <h2>Sub Providers</h2>
            {provider.subProviders.map((subProvider: any, j:number) => {

              return (
                <div key={j} className="subProvider">
                  <input className="third" onChange={controller.handleSubProviderChange.bind(this, j)} value={subProvider.name} name="name" placeholder="Sub Provider's Name" />
                  <PhoneInput
                    className="third"
                    country="US"
                    displayInitialValueAsLocalNumber
                    placeholder="Sub Provider's Phone"
                    value={ subProvider.phone }
                    onChange={ (value:any) => this.editSubPhone(j, value) }/>
                  <input className="third" onChange={controller.handleSubProviderChange.bind(this, j)} value={subProvider.email} name="email" placeholder="Sub Provider's E-Mail" />
                  <button className="removeSub" onClick={controller.removeSubProvider.bind(this, subProvider.id, j)}>-</button>
                  <div className="clearfix"></div>
                </div>
              )
            })}
            <div className="clearfix"></div>

            <button className="addSub" onClick={this.addSubProvider.bind(this)}>+ Add a new sub provider</button>


            <button className={"update "+((provider.id && provider.updated)? "updated" : "")} onClick={this.updateProvider} disabled={provider.updated || this.state.updating}>
              {this.state.updating? "Updating" :
                provider.id? provider.updated? "Updated" :
                "Update" :
                "Save"}
            </button>
            </form>
        </div>
    )
  }
}
