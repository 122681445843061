import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { toast } from 'react-toastify';

export default class Addresses extends React.Component<any, any> {
  searchString: any

  constructor(props: any) {
    super(props);
    this.state = {
      addresses: []
    }
  }

  search(event: any) {
    if(event.target.value.length < 2 || event.target.value.substring(0,1) == "-") {
      this.setState({addresses:[]})
      return
    }
    fetch('tasks/searchAddress', {
      method: 'POST',
      body: JSON.stringify({searchString: event.target.value})
    }).then(results => {
      return results.json()
    }).then(data => {
      let addresses = data.addresses.filter((address: any) => {
        return !address.name.toLowerCase().includes("directed")
      })
      this.setState({addresses: addresses})
    }).catch(err => {
      toast.error(err.message);
    })
  }

  removeAddress(id: number, index:number, event: any) {
    event.preventDefault()
    let addresses = this.state.addresses
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    console.log(id)
    if(id) {
      fetch('tasks/deleteAddress', {
        method: 'POST',
        body: JSON.stringify({id})
      }).then(results => {
        addresses.splice(index, 1)
        this.setState({addresses})
        toast.success("Address removed")
      }).catch(err => {
        toast.error(err.message);
      })
    } else {
      addresses.splice(index, 1)
      this.setState({addresses})
      toast.success("Address removed")

    }
  }

  componentDidMount() {

  }

  componentWillMount() {

  }

  componentWillUnmount() {
  }


  public render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'Address',
      accessor: 'address'
    }, {
      id: 'settings',
      Header: 'Settings',
      sortable: false,
      maxWidth: 100,
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>
        <Link to={"/addresses/" + row.value.id}><i className="fas fa-edit"></i></Link>
        <i onClick={this.removeAddress.bind(this, row.value.id, row.index)} className="fas fa-trash-alt"></i>
      </div>
    }]

    return (
      <div id="users-root">
        <input className="search" type="text" placeholder="Search address by name" onKeyUp={this.search.bind(this)} ref={(searchString:any) => this.searchString = searchString} />

        <div className="board">
          <ReactTable
            data={this.state.addresses}
            columns={columns}
            noDataText="No addresses found"
            pageSizeOptions={[100,300,500,1000]}
            defaultPageSize={100}
            style={{
              height: "calc(100vh - 120px)",
              textAlign: "center",
              fontSize: "1em",
              position: "centner",
              right: "0",
              left: "0",
              margin: "0 auto"
            }}
            className="-striped -highlight reactTable"
          />
        </div>
      </div>
    )
  }

}
