import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Tasks from '../tasks/Tasks';
import TasksArchive from '../tasksArchive/TasksArchive';
import TaskPage from '../task/Task';
import OfficeData from '../taskData/office/OfficeData';
import ClientData from '../taskData/client/ClientData';


export default class TasksRouter extends React.Component<any, any> {
  loadTasks: any
  sliderIndex:number = 0
  constructor(props:any) {
    super(props)
    this.state = {
      tasksArrays: [],
      tableView: false
    }
    this.loadTasks = props.loadTasks.bind(this)
  }

  componentDidMount() {
    this.setState({tasksArrays: this.props.tasksArrays})
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({tasksArrays: nextProps.tasksArrays})
  }

  updateSliderIndex(index: number) {
    this.sliderIndex = index
  }

  toggleTasksView() {
    localStorage.setItem("scrollPosition", "0");
    this.setState({tableView: !this.state.tableView})
  }

  public render() {
    return (
      <div id="tasks-router-root">
        <div className="buttonsHeader">
          <Link tabIndex={-1} to="/tasks/new"><button>Create A Task</button></Link>
          <Link tabIndex={-1} to="/tasks/archive"><button>Archive</button></Link>
        </div>

        <Switch>
          <Route exact path={`/`} render={(props:any) => <Tasks {...props}
          toggleTasksView={this.toggleTasksView.bind(this)}
          tableView={this.state.tableView}
          loadTasks={this.loadTasks}
          tasksArrays={this.state.tasksArrays}
          sliderIndex={this.sliderIndex}
          updateSliderIndex={this.updateSliderIndex.bind(this)} />} />
          <Route exact path={`/tasks`} render={(props:any) => <Tasks {...props}
          toggleTasksView={this.toggleTasksView.bind(this)}
          tableView={this.state.tableView}
          loadTasks={this.loadTasks}
          tasksArrays={this.state.tasksArrays}
          sliderIndex={this.sliderIndex}
          updateSliderIndex={this.updateSliderIndex.bind(this)} />} />
          <Route exact path={`/tasks/archive`} component={TasksArchive} />
          <Route exact path={`/tasks/new`} component={TaskPage} />
          <Route exact path={`/tasks/archive/:id`} component={TaskPage} />
          <Route exact path={`/tasks/:id`} component={TaskPage} />
          <Route exact path={`/tasks/:id/data/office`} component={OfficeData} />
          <Route exact path={`/tasks/:id/data/client`} component={ClientData} />
          <Route exact path={`/tasks/archive/:id/data/office`} component={OfficeData} />
          <Route exact path={`/tasks/archive/:id/data/client`} component={ClientData} />
        </Switch>

      </div>
    );
  }
}
