import fetchIntercept from 'fetch-intercept';

const unregister = fetchIntercept.register({
  request: function (url, config) {
    var myHeaders = new Headers();
    let account = JSON.parse(window.localStorage.getItem('account'));
      if(!account) account = {privilege: "public"}
      if(config.headers && config.headers["Content-Type"] == "multipart/form-data") {
        config.body.append("accountPrivilege", account.privilege)
      } else {
        // add accountType agents by default if not specified
        let temp = JSON.parse(config.body || "{}");
        if(account)
          temp.accountPrivilege = account.privilege;
        config.body = JSON.stringify(temp);
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json')
      }

    let finalUrl = null;
    if (url.indexOf('http') === -1) {
      finalUrl = `${process.env.REACT_APP_DOMAIN}/v1/${url}`;
      myHeaders.append('Authorization', authHeader(account));
    } else {
      finalUrl = url;
    }
    config.headers = myHeaders;
    return [finalUrl, config];
  },

  requestError: function (error) {
    // Called when an error occurred during another 'request' interceptor call
    // tslint:disable-next-line:no-console
    //console.log(error)
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the response object
    // tslint:disable-next-line:no-console
    //console.log(response);
    if (response.status === 403) {
      window.location.href = '/login'
      //  return Promise.reject();
    }
    if (!response.ok) { // we need to check on the ok property in order to know if we have forbidden or OK status
      return response.json().then((res) => {
        return Promise.reject(res);
      })

    }
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error only network error catches here

    return Promise.reject(error);
  }
});


export function authHeader(account) {
  // return authorization header with jwt token
  if (account && account.token) {
    return account.token;
  } else {
    return '';
  }
}
