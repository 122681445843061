import * as React from 'react';
import { Route, Switch } from 'react-router';
import ClientData from '../taskData/client/ClientData';
import Provider from './provider/Provider';

export default class Track extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
  }



  public render() {
    return (
      <div id="router-root">
          <Switch>
            <Route path={`/track/provider/:id/:token`} component={Provider} />
            <Route path={`/track/client/:token`} component={ClientData} />
          </Switch>
      </div>
    );
  }
}
