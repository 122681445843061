export const CarTypes = Object.freeze({"owner":"owner", "company":"company"})

export class Car {
  public id: number
  public name: string
  public type: string
  public updated: boolean
  public qboId: string
  public syncToken: number

  constructor(car:any = {}) {
    this.id = car.id || null
    this.name = car.name || ""
    this.type = car.type || CarTypes.owner
    this.updated = true
    this.qboId = car.qboId || null
    this.syncToken = car.syncToken || null
  }
}
