import * as React from 'react';
import '../TaskData.css';
import * as Moment from 'moment-timezone'
import {Task} from '../../../_models/Task'
import { Link } from 'react-router-dom';
import * as controller from '../controller'
var Timekeeper = require('react-timekeeper').default;
import { toast } from 'react-toastify';
import ProviderRoute from "./ProviderRoute";
const Loader = require('react-loader-spinner').default;
const ModalImage = require('react-modal-image').default

export default class OfficeData extends React.Component<any, any> {
  map: any;
  directionsService: any;
  directionsDisplay: any;
  loadTask: any
  calculateAndDisplayRoute: any
  loadMap: any
  address: any
  marker: any
  changeAddress: any
  activeIndex: number
  constructor(props: any) {
    super(props);
    this.state = {
      task: null,
      clientTask: null,
      locationsTimes: [],
      savingTask: false,
      addressPopupOpened: false,
      updating: false
    }
    this.calculateAndDisplayRoute = controller.calculateAndDisplayRoute.bind(this)
    this.loadTask = controller.loadTask.bind(this)
    this.loadMap = controller.loadMap.bind(this)
    this.changeAddress = controller.changeAddress.bind(this)
  }

  componentDidMount() {
    //Google Maps
    this.loadMap(true)
  }

  removeLocation(index: number) {
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    let task = this.state.task
    if(task.locations[index].id) {
      fetch('tasks/deleteLocation', {
        method: 'POST',
        body: JSON.stringify({id: task.locations[index].id})
      }).catch(err => {
        toast.error(err.message);
      })
    }

    task.locations.splice(index,1)
    this.setState({task}, () => {
      this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, this.state.task);
    })
  }


  removeSignature(index: number) {
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    let task = this.state.task
    if(task.signatures[index].id) {
      fetch('tasks/deleteSignature', {
        method: 'POST',
        body: JSON.stringify({id: task.signatures[index].id})
      }).catch(err => {
        toast.error(err.message);
      })
    }

    task.signatures.splice(index,1)
    this.setState({task})
  }

  handleLocationTimeChange(index: number, newTime: any) {
    let task = this.state.task
    let date = Moment.tz(task.locations[index].time, process.env.REACT_APP_TIMEZONE || "")
    let h = newTime.formatted24.split(":")[0]
    let m = newTime.formatted24.split(":")[1]
    date.set({h,m})
    task.locations[index].time = date.format()
    this.setState({task})
  }

  saveData() {
    let task = this.state.task
    this.setState({savingTask: true, updating: true})
    fetch('tasks/edit', {
      method: 'POST',
      body: JSON.stringify(task)
    }).then(results => {
      return results.json();
    }).then(data => {
      let task = new Task(data.task)
      this.setState({task, savingTask: false, updating: false}, () => {
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, task)
        toast.success("Saved")

      })
    }).catch(err => {
      this.setState({savingTask: false, updating: false})
      toast.error(err.message);
    })
  }

  addLocation() {
    let task = this.state.task
    task.locations.push({type: "location", time: new Date(), address: ""})
    this.setState({task}, () => {
      this.changeAddress(task.locations.length-1, "task")
    })
  }

  changeLocType(event: any) {
    let task = this.state.task
    task.locations[this.activeIndex].type = event.target.value
    this.setState({task})
  }

  public render() {
    let task = this.state.task
    let clientTask = this.state.clientTask
    let layouts = task && task.expenses.length>0 ? "Yes" : "No"
    let total = 0.0
    let providerCost = 0.0
    let providerCharge = 0.0
    let fee = 0.0
    let profit = 0.0
    let duration = Moment()
    if(task) {

      for(let i = 0; i<task.expenses.length; i++)
        total += parseFloat(task.expenses[i].amount)

      if(task.startTime) {
        let timeWorked = 0
        if(task.endTime) {
          duration = Moment.utc(Moment(task.endTime, "hh:mm A").diff(Moment(task.startTime, "hh:mm A")))
          timeWorked = duration.hours() + duration.minutes()/60
        }
        providerCost = timeWorked*task.providerHourRate + task.providerFlatRate + task.providerTolls + task.providerTip
        //providerCost += Math.ceil((task.providerPRate/100)*providerCost)
        providerCharge = timeWorked*task.clientHourRate + task.clientFlatRate + task.clientTolls + task.clientTip
        //providerCharge += Math.ceil((task.clientPRate/100)*providerCharge)
        profit = (timeWorked*task.clientHourRate + task.clientFlatRate + task.clientTip) - (timeWorked*task.providerHourRate + task.providerFlatRate + task.providerTip)
      }


    }


    return (
      <div id="task-data-root">
      {this.state.updating && <div className="loadingDiv">
        <Loader
           type="Oval"
           color="#e13c34"
           height="100"
           width="100"
        />
      </div>}
        {task && this.state.addressPopupOpened && <div className="addressPopup">
          <i onClick={() => {this.setState({addressPopupOpened: false})}} className="fas fa-times"/>
          <select value={task.locations[this.activeIndex].type} onChange={this.changeLocType.bind(this)}>
            <option value="start">Start Clock</option>
            <option value="eta">Pinned at ETA</option>
            <option value="location">On Location</option>
            <option value="completed">Completed</option>
            <option value="stop">Stop Clock</option>
          </select>
          <input type="text" id="address" ref={(ref:any) => this.address = ref} placeholder="Address" />
        </div>}
        <div className="container">
            {task && <Link to={this.props.match.path.replace(":id/data/office", task.id+ this.props.location.search)}><i className="fas fa-times"/></Link>}
            <div className="clearfix"/>
            {task && clientTask && <div>
            <ul className="box">
              <h3>Client Task Summery:</h3>
              <li><strong>Date:</strong> {clientTask.date}</li>
              <li><strong>Start Time:</strong> {clientTask.startTime}</li>
              <li><strong>End Time:</strong> {clientTask.endTime}</li>
              <li><strong>Duration:</strong> {clientTask.endTime && Moment.utc(Moment(clientTask.endTime, "hh:mm A").diff(
                Moment(clientTask.startTime, "hh:mm A"))).format("HH:mm")}</li>
              <li><strong>Layouts:</strong> <span className="red">{clientTask.layouts? "Yes" : "No"}</span></li>
            </ul>
            <ul className="box">
              <h3>Office Task Summery:</h3>
              <li><strong>Date:</strong> {task.date}</li>
              <li><strong>Start Time:</strong> {task.startTime}</li>
              <li><strong>End Time:</strong> {task.endTime}</li>
              <li><strong>Duration:</strong> {task.endTime && duration.format("HH:mm")}</li>
              <li><strong>Layouts:</strong> <span className="red">{layouts}</span></li>
            </ul>
            <ul className="box">
              <h3>Tolls & Tips:</h3>
              <li><strong>Rating:</strong> {task.rating || 0} of 5 stars</li>
              <li><strong>Client Tolls:</strong> ${task.clientTolls || 0}</li>
              <li><strong>Client Tip:</strong> ${task.clientTip || 0}</li>
              <li><strong>Provider Tolls:</strong> ${task.providerTolls || 0}</li>
              <li><strong>Provider Tip:</strong> ${task.providerTip || 0}</li>
            </ul>
            <ul className="box">
              <h3>Costs & Profits:</h3>
              <li><strong>Layouts & Expenses:</strong> ${total.toFixed(2)}</li>
              <li><strong>Provider Cost:</strong> ${providerCost.toFixed(2)}</li>
              <li><strong>Provider Charge:</strong> ${providerCharge.toFixed(2)}</li>
              <li><strong>ServeTie Fee:</strong> ${fee.toFixed(2)}</li>
              <li><strong className="red">ServeTie Profit: ${profit.toFixed(2)}</strong> </li>
            </ul>
            <ul className="box">
            <h3>Provider Data:</h3>
            {task.locations.map((location: any, i:number) => {
              let text
              if(location.type == "start") {
                text = "Start Clock"
              } else if (location.type == "completed"){
                text = "Completed"
              } else if (location.type == "eta"){
                text = "Pinned at ETA"
              } else if(location.type == "location") {
                text = "On Location"
              } else if(location.type == "stop") {
                text = "Stopped Clock"
              } else if(location.type == "pause") {
                text = "Paused"
              } else if(location.type == "unpause") {
                text = "Unpaused"
              }
              let time = Moment.tz(location.time, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A")
              return (<li className="location" key={i}><i onClick={this.removeLocation.bind(this, i)} className="fa fa-times"/>
              <i className="number">{String.fromCharCode(97 + i).toUpperCase()}</i>
              <strong>{text}:</strong>
              <span onClick={() => {
                this.setState({locationsTimes: []}, () => {
                  this.state.locationsTimes[i] = true
                  this.setState({locationsTimes: this.state.locationsTimes})
                })
              }} >{time}</span>

              <h6 onClick={controller.changeAddress.bind(this, i, "task")}>{location.address}</h6>

              {this.state.locationsTimes[i] && <Timekeeper onChange={this.handleLocationTimeChange.bind(this, i)}
                            onDoneClick={() => {this.setState({locationsTimes: []})}}
                            time={time}
                            switchToMinuteOnHourSelect={true}
                            closeOnMinuteSelect={true} />}</li>)
            })}
            <h4 className="addLocation" onClick={this.addLocation.bind(this)}>+ Add location</h4>

            </ul>

            </div>}
            <div id="map"/>
            <ProviderRoute taskId={task?.id} pickup={task?.pickup} middle={task?.middle} dropoff={task?.dropoff}/>


            {task && clientTask && <div>
              <div className="clearfix"/>
              <ul className="box">
                <h3>Signatures:</h3>
                {task.signatures.map((signature: any, i: number) => {
                  return (<li className="signature" key={i}>
                    <i onClick={this.removeSignature.bind(this, i)} className="fa fa-times"/>
                    <strong>{signature.name}:</strong>
                    <div className="sigData" style={{backgroundImage: 'url('+process.env.REACT_APP_AWS+"/tasks/"+task.id+"/signatures/"+signature.data+')'}}/>
                  </li>)
                })}
              </ul>
              <div className="clearfix"/>
              <ul className="box imageBox">
                <h3>Images & Receipts:</h3>

                {task.expenses.map((expense: any, i: number) => {
                  let expenseUrl = process.env.REACT_APP_AWS+"/tasks/"+task.id+"/expenses/"+expense.image
                  return (<li className="expense" key={i}>
                    {expense.name && expense.name.length > 0 && <strong>{expense.name}:</strong>}
                    <ModalImage
                      key={i}
                      small={expenseUrl}
                      large={expenseUrl}
                    />
                  </li>)
                })}

                {task.images.map((image: any, i: number) => {
                  let imageUrl = process.env.REACT_APP_AWS+"/tasks/"+task.id+"/images/"+image.url
                  return (<li className="image" key={i}>
                    <ModalImage
                      key={i}
                      small={imageUrl}
                      large={imageUrl}
                    />
                  </li>)
                })}

              </ul>

            </div>}
            <div className="buttons">
              {task && <Link to={this.props.match.path.replace(":id/data/office", task.id+"/data/client"+ this.props.location.search)}><button className="primary-btn">Edit Client Data</button></Link>}
              <button className="primary-btn green" onClick={this.saveData.bind(this)}>Save Changes</button>
            </div>
          </div>
      </div>
    )
  }
}
