import * as React from 'react';
import Select from 'react-select';
import {customSelectStyles, themeStyles} from './themeStyles.js'
import './popup.css';
import {CarTypes} from '../../../_models/Car'

export class AssignVehicle extends React.Component<any, any> {
  closePopup: any;
  constructor(props: any) {
    super(props);
    this.state = {
      task: null,
      cars: [],
      carsF: [],
      types: require(process.env.REACT_APP_DOMAIN? "../../../assets/taskTypes" : "../../../assets/taskTypes_dev"),
    }
    this.closePopup = this.props.closePopup
  }


  componentDidMount() {
    this.setState({
      cars: this.props.cars,
      carsF: this.props.cars,
      task: this.props.task
    })
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      cars: nextProps.cars,
      task: nextProps.task
    })
  }

  chooseCarType(event:any) {
    let type = event.target.value
    let cars = this.state.cars
    let filteredCars = cars.filter((car:any) => {
      return car.type == type
    })
    this.setState({carsF: filteredCars})
  }

  chooseCar(selectedOption: any) {
    let task = this.state.task
    task.car =  {
      id: selectedOption.value,
      name: selectedOption.label,
      type: selectedOption.type
    }
    task.updated = false
    this.setState({task})
  }

  chooseTaskType(selectedOption: any) {
    let task = this.state.task
    task.type = selectedOption.value
    task.updated = false
    this.setState({task})
  }

  public render() {
    let task = this.state.task
    let car = task && task.car
    let type = task? this.state.types.filter((type:any)=>{return type.value == task.type})[0] : null
    return (
      <div id="popup-root">
          <i onClick={this.closePopup} className="fas fa-times"></i>

          <div className="container">
          {task && <div>
            <h2>Assign Vehicle/Type:</h2>
            <div className="right">
              <div className="input">
                <label>Vehicle Type:</label>
                <input type="radio" name="carType" value={CarTypes.owner} defaultChecked={car && car.type == CarTypes.owner} onClick={this.chooseCarType.bind(this)} /><span>Owner</span>
                <input type="radio" name="carType" value={CarTypes.company} defaultChecked={car && car.type == CarTypes.company} onClick={this.chooseCarType.bind(this)}  /><span>Company</span>
                <div className="clearfix"></div>
              </div>
              <div className="input">
                <label>Select Vehicle:</label>
                <div className="selectContainer">
                  <Select
                    theme={themeStyles}
                    styles={customSelectStyles}
                    className="multiSelect"
                    classNamePrefix="react-select"
                    value={car && {value: car.id, label: car.name}}
                    onChange={this.chooseCar.bind(this)}
                    options={this.state.carsF}
                    menuPlacement={"bottom"}
                    placeholder={"Select"}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="left">
            <div className="input">
              <label>Select Task Type:</label>
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={type}
                  onChange={this.chooseTaskType.bind(this)}
                  options={this.state.types}
                  menuPlacement={"bottom"}
                  placeholder={"Select"}
                />
              </div>
              </div>
            </div>
            </div>}
          </div>

      </div>
    )
  }

}
