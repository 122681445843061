import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Clients from '../clients/Clients';
import ClientPage from '../client/Client';

export default class ClientsRouter extends React.Component<any, any> {

  public render() {
    return (
      <div id="clients-router-root">
        <div className="buttonsHeader">
          <Link to="/clients/new"><button>Add a Client</button></Link>
          <Link to="/clients/new"><button>Export Clients</button></Link>
        </div>

        <Switch>
          <Route exact path={`/clients`} component={Clients} />
          <Route exact path={`/clients/new`} component={ClientPage} />
          <Route exact path={`/clients/:id`} component={ClientPage} />
        </Switch>

      </div>
    );
  }
}
