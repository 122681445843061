import {Client} from '../../_models/Client'
import { toast } from 'react-toastify';

  export function updateClient(this:any, event:any) {
    event.preventDefault()
    this.setState({updating: true})
    fetch('clients/edit', {
      method: 'POST',
      body: JSON.stringify(this.state.client)
    }).then(results => {
      return results.json();
    }).then(data => {
        let client = new Client(data.client)
        client.updated = true
        if(!this.state.client.id) {
          //New client, go back
          this.props.history.push("/clients")
          toast.success("Client created successfuly")
        } else {
          //It's an update
          toast.success("Client updated successfuly")
          this.setState({client, updating: false})
        }
    }).catch(err => {
      this.setState({updating: false})
      toast.error(err.message);
    })


  }

  export function removeClient(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    fetch('clients/delete', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(() => {
      let clients = this.state.clients
      clients.splice(index, 1)
      this.setState({clients})
      toast.success("Client removed successfuly")
    }).catch(err => {
      toast.error(err.message);
    })
  }


  export function handleSelectChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      } else {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      }
    });
  }



    export function editPhone(this:any, phoneNum:any) {
      let client = this.state.client
      client.phone = phoneNum || ""
      client.updated = false
      this.setState({client});
    }

  export function handleClientChange(this:any, event: any) {
    let client = this.state.client
    client[event.target.name] = event.target.value
    client.updated = false
    this.setState({client});
  }



  export function handleSubClientChange(this:any, index: number, event: any) {
    let client = this.state.client
    client.subClients[index][event.target.name] = event.target.value
    client.updated = false
    this.setState({client});
  }

  export function handleProjectChange(this:any, index: number, event: any) {
    let client = this.state.client
    client.projects[index][event.target.name] = event.target.value
    client.updated = false
    this.setState({client});
  }

  export function editSubPhone(this:any, index: number, phoneNum:any) {
    let client = this.state.client
    client.subClients[index].phone = phoneNum || ""
    client.updated = false
    this.setState({client});
  }

  export function removeSubClient(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let client = this.state.client
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    if(id) {
      fetch('clients/deleteSub', {
        method: 'POST',
        body: JSON.stringify({id})
      }).then(() => {
        client.subClients.splice(index, 1)
        toast.success("Sub Client removed successfuly")
        this.setState({client})
      }).catch(err => {
        toast.error(err.message);
      })
    } else {
      client.subClients.splice(index, 1)
      toast.success("Sub Client removed successfuly")
      this.setState({client})
    }


  }


  export function removeProject(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let client = this.state.client
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    if(id) {
      fetch('projects/delete', {
        method: 'POST',
        body: JSON.stringify({id})
      }).then(() => {
        client.projects.splice(index, 1)
        toast.success("Project removed successfuly")
        this.setState({client})
      }).catch(err => {
        toast.error(err.message);
      })
    } else {
      client.projects.splice(index, 1)
      toast.success("Project removed successfuly")
      this.setState({client})
    }


  }
