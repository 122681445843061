import * as React from 'react';
import Messages from './Messages'
import { toast } from 'react-toastify';
import './Chat.css';

export default class Chat extends React.Component<any, any> {
  socket: any
  constructor(props: any) {
    super(props);
    this.state = {
      chats: [],
      users: [],
      selectedChat: null
    }
    this.filterChatsByUser = this.filterChatsByUser.bind(this)
  }

  toggleUser(index: number) {
    let users = this.state.users
    let user = users[index]
    user.selected = !user.selected
    this.setState({users}, this.filterChatsByUser)
  }

  filterChatsByUser() {
    let chats = this.state.chats.map((chat: any) => {
      if(chat.status != "assigned") {
        chat.show = true
      } else {
        chat.show = false
        this.state.users.map((user: any) => {
          if(user.selected && chat.userId == user.id) {
            chat.show = true
          }
        })
      }
      return chat
    })
    this.setState({chats})
  }

  senderName(chat: any) {
    if(!chat) return
    let senderName = chat.phone
    if(chat.provider) senderName = chat.provider.name
    if(chat.client) senderName = chat.client.name
    if(chat.subProvider) senderName = chat.subProvider.name
    if(chat.subClient) senderName = chat.subClient.name
    return senderName
  }

  search(event: any) {
    let name = event.target.value.toLowerCase()
    let chats = this.state.chats.map((chat: any) => {
      chat.show = false
      let senderName = this.senderName(chat)
      if(!name.length || senderName.toLowerCase().includes(name))
        chat.show = true

      return chat
    })
    this.setState({chats})
  }


  componentDidMount() {
    fetch('chats/getAllUsers', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let users = data.users
        users = users.map((user: any) => {
          user.selected = true
          return user
        })
        this.setState({users})
    }).catch(err => {
      toast.error(err.message);
    })
  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.selectedChat != nextProps.match.params.id) {
      this.selectChat(nextProps.match.params.id)
    }
  }

  componentWillMount() {
    this.socket = this.props.socket
    fetch('chats/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let chatId = this.props.match.params.id? this.props.match.params.id : data.chats[0]? data.chats[0].id : null
        this.setState({chats: data.chats, selectedChat: chatId}, () => {
          this.socket.on('newChat', (newChat: any) => {
            if(!newChat) return
            let chats = this.state.chats
            let foundChatIndex = this.state.chats.findIndex((chat: any) => {
              return chat.id == newChat.id
            })
            if(foundChatIndex === -1) {
              //add the new chat to the list, all users should see this
              chats.push(newChat)
              this.setState({chats}, this.filterChatsByUser)
            } else {
              //This is an exsiting chat
              chats[foundChatIndex] = newChat
              this.setState({chats}, this.filterChatsByUser)
            }

          });

          this.socket.on('removeChat', (chatId: number) => {
            if(!chatId) return
            let chats = this.state.chats.filter((chat: any) => {
              return chat.id != chatId
            })
            let selectedChat = this.state.selectedChat
            if(selectedChat == chatId) {
              this.setState({chats, selectedChat: chats[0].id})
            } else {
              this.setState({chats})
            }

          });
        })
    }).catch(err => {
      toast.error(err.message);
    })

  }

  componentWillUnmount() {
    if(this.socket) {
      this.socket.removeListener('newChat')
      this.socket.removeListener('removeChat')
    }

  }

  selectChat(chatId: number) {
    this.setState({selectedChat: chatId} , () => {
      this.props.history.push("/chat/"+chatId)
    })
  }



  public render() {
    return (
      <div id="chat-root">
        <ul className="users">
          {this.state.users.map((user: any, i:number) => {
            return (
              <div key={i} className="box">
                <div onClick={this.toggleUser.bind(this, i)}
                  style={{backgroundImage: "url("+user.picture+")"}} className={user.selected? "selected user":"user"}></div>
              </div>)
          })}
        </ul>
        <ul className="chats">
          <input placeholder="Search chat..." onChange={this.search.bind(this)}/>
          {this.state.chats.map((chat: any, i:number) => {
            let senderName = this.senderName(chat)
            if(chat.show) {
              return <li onClick={this.selectChat.bind(this, chat.id)} key={i} className={chat.status + " " + (this.state.selectedChat==chat.id? "selected" : "")}>{senderName}</li>
            } else {
              return
            }

          })}
        </ul>

        <Messages socket={this.socket} senderName={this.senderName} selectedChat={this.state.selectedChat} />


      </div>
    )
  }

}
