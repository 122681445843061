import * as React from 'react';
import './Login.css';
import logo from '../../assets/logo.png';


export default class Login extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: ""
    };
    this.handleChange = this.handleChange.bind(this)

  }

  handleChange(event: any) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  login(email: string, password: string) {
      fetch('public/login', {
        method: 'post',
        body: JSON.stringify({ email, password })
      })
      .then(results => {
        return results.json();
      }).then(data => {
        window.localStorage.setItem('account', JSON.stringify(data.user));
        window.location.href = '/';
      }).catch((error) => {
        this.setState({error: error.message})
      })

  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  public render() {
    return (
      <div id="login-root" >
        <div className="left">
          <form>
            <img className="main_logo" src={logo} />
            <input type="email" autoFocus className="form-control" name="email" placeholder="E-Mail Address" value={this.state.email}
            onChange={this.handleChange} />
            <input type="password" className="form-control" name="password" placeholder="Password" value={this.state.password}
              onChange={this.handleChange} />
            <button type="button" className={"primary-btn" + (this.validateForm()? "" : " off")} disabled={!this.validateForm()} onClick={this.login.bind(this, this.state.email, this.state.password)}>Login</button>
            <a>Forgot password?</a>
            <h5>{this.state.error}</h5>
          </form>
        </div>
        <div className="right"></div>
      </div>
    );
  }
}
