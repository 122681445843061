const { io } = require('socket.io-client');

const socket = io( process.env.REACT_APP_DOMAIN || "http://localhost:5030", {
  transports: ['websocket']
});

socket.on('connect_error', (err:any) => {
  // eslint-disable-next-line no-console
  console.error(err);
});

export default socket;
