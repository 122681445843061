import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Nav from "../../_layout/nav/Nav";
import TasksRouter from "./TasksRouter";
import ProvidersRouter from "./ProvidersRouter";
import ClientsRouter from "./ClientsRouter";
import CarsRouter from "./CarsRouter";
import ShopsRouter from "./ShopsRouter";
import Addresses from "../addresses/Addresses";
import Analytics from "../analytics/Analytics";
import AddressPage from "../address/Address";
import Chat from "../chat/Chat";
import Projects from "../projects/Projects";
import ActiveProvidersMap from "../ActiveProvidersMap";

import { ToastContainer } from "react-toastify";
import socket from "../../socket";
import { Task } from "../../_models/Task";

import { toast } from "react-toastify";
import icon from "../../assets/favicon.png";

export default class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      account: {},
      desktopMenuOpen: true,
      unassigned: [],
      assigned: [],
      active: [],
      completed: [],
    };
    this.loadUnassignedTasks = this.loadUnassignedTasks.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.checkPath = this.checkPath.bind(this);
  }

  loadTasks() {
    return fetch("tasks/getAll", {
      method: "POST",
      body: JSON.stringify({ archived: false }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let tasks = data.tasks;
        let arrays:any = {
          unassigned: [],
          assigned: [],
          active: [],
          completed: [],
        };
        tasks.map((task: any) => {
          task = new Task(task);
          let array = task.status;
          arrays[array].push(task);
        });

        //flip completed
        arrays.completed.reverse();
        //set the arrays, pretty cool :)
        return Promise.all(
          Object.keys(arrays).map((array: any) => {
            return new Promise((resolve: any, reject: any) => {
              this.setState({ [array]: arrays[array] }, () => {
                return resolve();
              });
            });
          })
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadUnassignedTasks() {
    fetch("tasks/getAllUnassigned", {
      method: "POST",
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let tasks = data.tasks;
        let unassigned: any[] = [];
        tasks.map((task: any) => {
          task = new Task(task);
          unassigned.push(task);
        });
        this.setState({ unassigned });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  componentWillMount() {
    let account = JSON.parse(localStorage.getItem("account") || "{}");
    if (!(account && account.token)) {
      this.props.history.push("/login");
    } else {
      window.account = account;
      socket.connect();
    }
  }

  componentWillUnmount() {
    socket.removeAllListeners();
    socket.disconnect();
  }

  checkPath() {
    let path = this.props.location.pathname;
    return path == "/" || path == "/tasks" || path == "/tasks/";
  }

  componentDidMount() {
    if (!this.checkPath())
      //this.loadUnassignedTasks();

      socket.on("provider_update", (data: any) => {
        if (!this.checkPath()) return;
        let task = new Task(data.task);
        let oldArray = data.oldArray;
        if (!oldArray) return;
        //Find the task in the arrays and remove
        let arrayToRemove = this.state[oldArray];
        arrayToRemove = arrayToRemove.filter((obj: any) => {
          return obj.id != task.id;
        });
        this.setState({ [oldArray]: arrayToRemove }, () => {
          //Add the task to the relevent array
          let arrayToPush = this.state[task.status];
          arrayToPush.push(task);
          this.setState({ [task.status]: arrayToPush });
        });
      });

    socket.on("task_delete", (data: any) => {
      if (!this.checkPath()) return;
      let taskId = data.id;
      let array = data.array;
      let newArray = this.state[array].filter((obj: any) => {
        return obj.id != taskId; //Removing the task from the array
      });
      this.setState({ [array]: newArray });
    });

    if ("Notification" in window) {
      if (Notification.permission == "default") {
        Notification.requestPermission();
      }
      socket.on("newChatMessage", (message: any) => {
        let chat = message.chat;
        let path = this.props.location.pathname;

        if (
          Notification.permission != "granted" ||
          !chat ||
          path == "/chat/" + chat.id
        )
          return;
        let senderName = chat.phone;
        if (chat.provider) senderName = chat.provider.name;
        if (chat.client) senderName = chat.client.name;
        if (chat.subProvider) senderName = chat.subProvider.name;
        if (chat.subClient) senderName = chat.subClient.name;
        if (
          chat.status != "assigned" ||
          (chat.userId == window.account.id && chat.status == "assigned")
        ) {
          let notification = new Notification(senderName, {
            body: message.body,
            icon: icon,
          });
          notification.onclick = (event: any) => {
            event.preventDefault();
            this.props.history.push("/chat/" + chat.id);
            notification.close();
          };
        }
      });
    }
  }

  toggleDesktopMenu() {
    this.setState({ desktopMenuOpen: !this.state.desktopMenuOpen });
  }

  public render() {
    let unassignedTasks = 0;
    this.state.unassigned.map((task: any) => {
      if (new Date().getTime() + 1.728e8 > task.rawStartTime) unassignedTasks++;
    });
    return (
      <div id="router-root">
        <ToastContainer
          position={window.innerWidth > 768 ? "top-right" : "bottom-center"}
          autoClose={5000}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnHover={true}
        />
        <Nav
          toggleDesktopMenu={this.toggleDesktopMenu.bind(this)}
          desktopMenuOpen={this.state.desktopMenuOpen}
          unassignedTasks={unassignedTasks}
        />
        <div
          className={
            this.state.desktopMenuOpen ? "open innerWindow" : "innerWindow"
          }
        >
          <Switch>
            <Route
              exact={true}
              path={`/`}
              render={(props: any) => (
                <TasksRouter
                  {...props}
                  loadTasks={this.loadTasks}
                  tasksArrays={[
                    this.state.unassigned,
                    this.state.assigned,
                    this.state.active,
                    this.state.completed,
                  ]}
                />
              )}
            />
            <Route
              path={`/tasks`}
              render={(props: any) => (
                <TasksRouter
                  {...props}
                  loadTasks={this.loadTasks}
                  tasksArrays={[
                    this.state.unassigned,
                    this.state.assigned,
                    this.state.active,
                    this.state.completed,
                  ]}
                />
              )}
            />
            <Route
              path={`/activeProvidersMap`}
              render={(props: any) => (
                <ActiveProvidersMap
                  socket={socket}
                />
              )}
            />
            <Route path={`/providers`} component={ProvidersRouter} />
            <Route path={`/clients`} component={ClientsRouter} />
            <Route path={`/cars`} component={CarsRouter} />
            <Route path={`/shops`} component={ShopsRouter} />
            <Route path={`/addresses/:id`} component={AddressPage} />
            <Route path={`/analytics`} component={Analytics} />

            <Route path={`/addresses`} component={Addresses} />
            <Route
              path={`/chat/:id`}
              render={(props: any) => <Chat {...props} socket={socket} />}
            />
            <Route
              path={`/chat`}
              render={(props: any) => <Chat {...props} socket={socket} />}
            />
            <Route path={`/projects`} component={Projects} />

            {/*this.state.account.role == "admin" && <Route path={`/users`} component={Users} />*/}
          </Switch>
        </div>
      </div>
    );
  }
}
