import * as React from 'react';
import * as Moment from 'moment-timezone'
import {Task, TaskStatus} from '../../_models/Task'
import { toast } from 'react-toastify';
import Select from 'react-select';
import {getLastAddressId} from "../../_helpers/other";

export function duplicateTask(this:any, event:any) {
  event.preventDefault()
  if(!this.state.task.updated) {
    toast.warn("Please first save your changes before duplicating")
    return
  }
  this.setState({loading: true})
  let task = this.state.task
  let newTask = new Task(task)
  delete newTask.id
  newTask.date = Moment().format("MM/DD/YYYY")
  newTask.startTime = "" //no start time
  newTask.endTime = "" //no end time
  newTask.provider = null
  newTask.subProvider = null
  newTask.notes = []
  newTask.expenses = []
  newTask.signatures = []
  newTask.locations = []
  newTask.status = TaskStatus.unassigned
  newTask.hourReminder = false
  newTask.dayReminder = false
  newTask.archived = false
  newTask.exported = false
  newTask.published = true
  newTask.updated = true

  newTask.pickup = task.pickup.map((address:any) => {
    delete address.updatedAt
    delete address.createdAt
    delete address.taskId
    delete address.id
    return address
  })

  newTask.dropoff = task.dropoff.map((address:any) => {
    delete address.updatedAt
    delete address.createdAt
    delete address.taskId
    delete address.id
    return address
  })

  newTask.middle = task.middle.map((address:any) => {
    delete address.updatedAt
    delete address.createdAt
    delete address.taskId
    delete address.id
    return address
  })

  newTask.notifications = task.notifications.map((notification:any) => {
    delete notification.updatedAt
    delete notification.createdAt
    delete notification.taskId
    delete notification.id
    return notification
  })

  return fetch('tasks/edit', {
    method: 'POST',
    body: JSON.stringify(newTask)
  }).then(() => {
    toast.success("Task was duplicated successfuly")
    this.setState({loading: false})
  }).catch(err => {
    this.setState({loading: false})
    toast.error(err.message);
  })
}


export function publishTask(this: any, redirectEnabled: boolean, event:any) {
    event.preventDefault()
    let task = this.state.task
    task.published = true
    this.setState({task}, ()=>{
      this.editTask(task, redirectEnabled)
    })
}

  export function completeTask(this: any, event:any) {
      event.preventDefault()
      if(!this.state.task.updated) {
        toast.warn("Please first save your changes before completing")
        return
      }
      return fetch('tasks/complete', {
        method: 'POST',
        body: JSON.stringify({id: this.state.task.id})
      }).then(() => {
        toast.success("Task was completed successfuly")
        this.props.history.push("/tasks")
      }).catch(err => {
        toast.error(err.message);
      })
  }


  export function cancelTask(this: any, event:any) {
      event.preventDefault()
      if(!this.state.task.updated) {
        toast.warn("Please first save your changes before cancelling")
        return
      }
      return fetch('tasks/cancel', {
        method: 'POST',
        body: JSON.stringify({id: this.state.task.id})
      }).then(() => {
        toast.success("Task was cancelled successfuly")
        this.props.history.push("/tasks")
      }).catch(err => {
        toast.error(err.message);
      })
  }

  export function archiveTask(this: any, event:any) {
      event.preventDefault()
      if(!this.state.task.updated) {
        toast.warn("Please first save your changes before archiving")
        return
      }
      return fetch('tasks/archive', {
        method: 'POST',
        body: JSON.stringify({id: this.state.task.id})
      }).then(() => {
        toast.success("Task was archived successfuly")
        this.props.history.push("/tasks")
      }).catch(err => {
        toast.error(err.message);
      })
  }

  export function exportTask(this: any, billable: boolean, event:any) {
      event.preventDefault()
      let task = this.state.task
      let confirmed = confirm("Are you sure you want to export? This is irreversible!")
      if(!confirmed) {
        return
      }

      if(!task.updated) {
        this.setState({billablePopupOpen: false})
        toast.warn("Please first save your changes before exporting to QuickBooks!")
        return
      }

      this.setState({loading: true, billablePopupOpen: false})

      if(!task.client || !task.provider || !task.car) {
        this.setState({loading: false})
        toast.warn("Assign a provider, vehicle and a client and try again.")
        return
      }

      let expensesOk = true

      task.expenses.map((expense: any) => {
        if(expense.payer == "admin" && !expense.shop) {
          expensesOk = false
        }
      })

      if(!expensesOk) {
        this.setState({loading: false})
        toast.warn("Check that you connected all your expenses to shops and try again.")
        return
      }

      if(!task.providerFlatRate && !task.providerHourRate) {
        this.setState({loading: false})
        toast.warn("Cannot export without provider rates")
        return
      }

      let flat = false

      if(!task.clientFlatRate && !task.clientHourRate) {
        this.setState({loading: false})
        toast.warn("Cannot export without client rates")
        return
      } else if(task.clientFlatRate && !task.clientHourRate) {
        flat = true;
      }

      if(!flat && (!task.endTime || task.endTime.length == 0)) {
        this.setState({loading: false})
        toast.warn("The task is hourly charged but has no end time, cannot export.")
        return
      }

      return fetch('tasks/export', {
        method: 'POST',
        body: JSON.stringify({id: task.id, billable})
      }).then(() => {
        toast.success("Task was exported to Quickbooks successfuly")
        this.setState({loading: false})
        if(this.props.match.path.includes("/archive/")) {
          this.props.history.push("/tasks/archive")
        } else {
          this.props.history.push("/tasks")
        }
      }).catch(err => {
        toast.error(err.message);
        this.setState({loading: false})
      })
  }


  export function updateTask(this:any, event:any) {
    if(event)
      event.preventDefault()
    let task = this.state.task
    this.editTask(task, false)
  }

  export function editTask(this:any, task:any, redirectEnabled:boolean) {

    if(!task.startTime) {
      toast.error("Task missing start time")
      return;
    }

    this.setState({loading: true})


    task.pickup = [{...task.addresses[0], type: "pickup"}] //first
    task.middle = task.addresses.slice(1, task.addresses.length-1).map((address:any) => {
      address.type = "middle"
      return address
    })
    task.dropoff = task.addresses.length > 1? [{...task.addresses[task.addresses.length-1], type: "dropoff"}] : [] //last

    return fetch('tasks/edit', {
      method: 'POST',
      body: JSON.stringify(task)
    }).then(results => {
      return results.json();
    }).then(data => {
      let task = new Task(data.task)
      task.updated = true
      if(!this.state.task.id || redirectEnabled) {
        //New task, go back
        this.setState({task, loading: false}, () => {
          this.props.history.push('/tasks')
          toast.success("Task created successfuly")
        })
      } else {
        //It's an update
        this.setState({task, loading: false})
        toast.success("Task updated successfuly")
      }
    }).catch(err => {
      this.setState({loading: false})
      toast.error(err.message);
    })
  }

  export function removeTask(this:any, id: number, status:string = "", event:any) {
    event.preventDefault()
    let confirmed = confirm("Are you sure you want to delete this task?")
    if(!confirmed) return
    fetch('tasks/delete', {
      method: 'POST',
      body: JSON.stringify({id: id})
    }).then(() => {
      toast.success("Task deleted successfuly")
      if(status && status.length > 0) {
        let tasks = this.state[status]
        tasks.splice(tasks.findIndex((task:any) => task.id == id), 1)
        this.setState({[status]: tasks})
      }
    }).catch(err => {
      toast.error(err.message);
    })
  }


  export function handleSelectChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        let task = this.state.task
        task.subClient = null
        task.project = null
        task.client =  {
          id: selectedOption.value,
          name: selectedOption.label,
          phone: selectedOption.phone,
          email: selectedOption.email
        }
        task.updated = false
        this.loadSubClients(task.client.id)
        this.setState({task})
      } else {
        let task = this.state.task
        task.client = null
        task.updated = false
        this.setState({task})
      }
    });
  }


  export function handleSelectSubChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        let task = this.state.task
        task.subClient = null
        task.project = null
        if(selectedOption.project) {
          task.project =  {
            id: selectedOption.value,
            name: selectedOption.label
          }
        } else {
          task.subClient =  {
            id: selectedOption.value,
            name: selectedOption.label,
            phone: selectedOption.phone,
            email: selectedOption.email
          }
        }
        task.updated = false
        this.setState({task})
      } else {
        let task = this.state.task
        task.client = null
        task.updated = false
        this.setState({task})
      }
    });
  }

  export function openTask(this:any, task: any, event: any) {
    task = task? task : new Task() //Setting to a new task if not passed
    this.setState({ taskOpen: true, task:  task });
  }

  export function handleStartTimeChange(this:any, value:any) {
    let task = this.state.task
    task.startTime = value.format("hh:mm A")
    task.updated = false
    this.setState({task: task})
  }

  export function handleEndTimeChange(this:any, value:any) {
    let task = this.state.task
    task.endTime = value.format("hh:mm A")
    task.updated = false
    this.setState({task: task})
  }

  export function handleDateChange(this:any, date:any) {
    let task = this.state.task
    task.date = Moment(date).format("MM/DD/YYYY")
    task.updated = false
    this.setState({task: task})
  }

  export function closeTimepickers(this:any) {
    this.setState({
      startTimeClicked: false,
      endTimeClicked: false
    })
  }


  export function handleChatChange(this:any, dividor: number) {
    let spacesCount = (this.textBox.value.match(/\n/g) || []).length;
    this.chatLines = Math.ceil(this.textBox.value.length/dividor) + spacesCount

    if(this.chatLines > 1) {
      this.textBox.style.height = this.chatLines * 40 + "px"
    } else {
      this.textBox.style.height = "50px"
    }
  }

  export function handleTaskChange(this:any, event: any) {
    let task = this.state.task
    task[event.target.name] = event.target.value
    task.updated = false
    this.setState(task);
  }

  export function keyPress(this:any, providerId:number, subProviderId: number, dividor: number, event: any) {
    if(event.keyCode == 13 && event.shiftKey) {
      event.preventDefault()
      this.textBox.value += "\n"
      this.handleChatChange(dividor)
      this.textBox.scrollTop = this.textBox.scrollHeight
    } else if(event.keyCode == 13) {
      event.preventDefault()
      this.sendChatNote(providerId, subProviderId)
    }
  }


  export function sendChatNote(this:any, providerId: number, subProviderId: number) {
    if(!this.textBox.value.length) return
    let task = this.state.task
    this.setState({loading: true})
    let newNote = {
      createdAt: Moment.tz(process.env.REACT_APP_TIMEZONE || "").format(),
      userId: window.account.id,
      providerId,
      subProviderId,
      note: this.textBox.value,
      user: {
        name: window.account.name //handle if task is new one
      }
    }
    let url
    if(providerId || subProviderId) {
      url = 'public/sendNote'
    } else {
      url = 'tasks/sendNote'
    }
    if(task.id) {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          note: newNote.note,
          userId: newNote.userId,
          providerId: newNote.providerId,
          id: task.id,
          token: this.token
        })
      }).then(results => {
        return results.json()
      }).then((data:any) => {
        task.notes.push(data.note)
        this.setState({task: task, loading: false}, () =>{
          this.textBox.value = ""
          this.textBox.style.height = "50px"
          this.chat.scrollTop = this.chat.scrollHeight
        })
      }).catch(err => {
        toast.error(err.message);
        this.setState({loading: false})
      })
    } else {
      task.updated = false
      task.notes.push(newNote)
      this.setState({task: task, loading: false}, () => {
        this.textBox.value = ""
        this.textBox.style.height = "50px"
        this.chat.scrollTop = this.chat.scrollHeight
      })
    }
  }

  export function changeAddressName(this:any, index: number, name: string, event:any) {
    let task = this.state.task
    task.addresses[index].name = name? name : event.target.value
    task.updated = false
    this.setState({task})
  }

  export function changeAddress(this: any, index: number, address:any) {
    let task = this.state.task
    task.addresses[index].address = address.value
    task.updated = false
    this.setState({task})
  }

//RENDER OBJECTS


  export class RenderChat extends React.Component<any,any>{
    constructor(props:any) {
      super(props)
      this.state = {
        task: {},
        userMode: false
      }
    }
    componentDidMount() {
      this.setState({task: this.props.task, userMode: this.props.userMode})
    }
    componentWillReceiveProps(nextProps: any) {
      this.setState({task: nextProps.task, userMode: nextProps.userMode})
    }
    render() {
      if(!this.state.task.notes) return <div>Error: no task notes</div>
      let messages: any[] = []
      let regex = new RegExp(/(((\d)\D)?(\(?(\d\d\d)\)?)?\D(\d\d\d)\D(\d\d\d\d))|(\b\d{10,11}\b)/g)
      for (let i = 0; i < this.state.task.notes.length; i++) {
        let message = this.state.task.notes[i]

        if(!message.note) return <div>Error on loading message id: {message.id}</div>;

        let note = message.note.split('\n').map((item:any, i:number) => {
            let markup = item.replace(regex, (phone:any) => {
              return '<a href="tel:'+phone+'">'+phone+'</a>'
            });
            return <p style={{display: "inline"}} key={i}><em dangerouslySetInnerHTML={{__html: markup}} /><br /></p>;
        })




        let sender
        let date = Moment.tz(message.createdAt, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY hh:mm A")
        let provider = message.subProvider? message.subProvider : message.provider
        if(message.user || provider) {
          if(this.state.userMode) {
            sender = provider? provider.name.split(" ")[0] : message.user.name.split(" ")[0]
            messages.push(<span className={sender == window.account.name.split(" ")[0]? "" : "red"} key={i}>{date} - {sender}: {note}</span>)
          } else {
            sender = provider? "You" : message.user.name.split(" ")[0]
            messages.push(<span className={sender == "You"? "" : "red"} key={i}>{date} - {sender}: {note}</span>)
          }
        } else {
          messages.push(<span className="grey" key={i}>{date} - System: {note}</span>)
        }
      }

      return messages
    }
  }

  export function toggleStatus(this:any) {
    let task = this.state.task
    if(task.status == TaskStatus.active) {
      task.status = TaskStatus.assigned
      task.updated = false
      this.setState({task})
    } else if(task.status == TaskStatus.assigned) {
      task.status = TaskStatus.active
      task.updated = false
      this.setState({task})
    }
    return
  }

  export const MobileAwareSelect = (props:any) => window.innerWidth<=768 ? (
      <select>{props.options.map((option: any, i:number) => {
        return <option key={i} value={option.value}>{option.label}</option>
      })}</select>
    ) : (
      <Select {...props} />
    );

  export class RenderStatus extends React.Component<any,any>{
      toggleStatus: any
      constructor(props:any) {
        super(props)
        this.state = {
          status: TaskStatus.unassigned
        }
        this.toggleStatus = this.props.toggleStatus
      }
      componentDidMount() {
        this.setState({status: this.props.status})
      }
      componentWillReceiveProps(nextProps: any) {
        this.setState({status: nextProps.status})
      }


      render() {
        return <span onClick={this.toggleStatus} className={"status "+this.state.status}>{this.state.status}</span>
      }
  }


      export class RenderAddresses extends React.Component<any,any>{
          addresses: any[];
          controller: any;
          changeAddress: any;
          changeAddressName: any;
          suggestions: any[] = [];
          constructor(props:any) {
            super(props)
            this.state = {
              task: null,
              suggestions: [],
              suggestionsFor: null
            }
            this.addresses = []
            this.controller = this.props.controller
            this.changeAddress = props.changeAddress
            this.changeAddressName = props.changeAddressName
            this.addGoogleListener = this.addGoogleListener.bind(this)
            this.addSuggestionsListener = this.addSuggestionsListener.bind(this)
          }
          componentDidMount() {
            this.setState({
              task: this.props.task
            })
          }
          componentWillReceiveProps(nextProps: any) {
            this.setState({
              task: nextProps.task
            })
          }

          getSuggestions(index: number, event:any) {
            if(event.target.value.length < 2 || event.target.value.substring(0,1) == "-") {
              this.setState({suggestions:[]})
              return
            }
            fetch('tasks/searchAddress', {
              method: 'POST',
              body: JSON.stringify({searchString: event.target.value})
            }).then(results => {
              return results.json()
            }).then(data => {
              let addresses = data.addresses.filter((address: any) => {
                return !address.name.toLowerCase().includes("directed")
              })
              this.setState({suggestions: addresses, suggestionsFor: index})
            }).catch(err => {
              toast.error(err.message);
            })
          }

          removeAddress(index:number, event: any) {
            event.preventDefault()
            let task = this.state.task
            if(task.addresses[index].id) {
              fetch('tasks/deleteAddress', {
                method: 'POST',
                body: JSON.stringify({id: task.addresses[index].id})
              }).then(results => {
                task.addresses.splice(index, 1)
                let addresses = task.addresses
                task.addresses = []
                this.addresses = []
                this.setState({task}, () => {
                  task.addresses = addresses
                  this.setState({task})
                })
              }).catch(err => {
                toast.error(err.message);
              })
            } else {
              task.addresses.splice(index, 1)
              let addresses = task.addresses
              task.addresses = []
              this.addresses = []
              this.setState({task}, () => {
                task.addresses = addresses
                this.setState({task})
              })
            }
          }

          addGoogleListener(element:any) {
              if(!element) return
              let index = parseInt(element.getAttribute("data-index"))
              this.addresses[index] = element
              if(this.addresses[index]) {
                let autocomplete = new window.google.maps.places.Autocomplete(this.addresses[index])
                window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                    this.changeAddress(index, this.addresses[index])
                });
              }
          }

          addSuggestionsListener(element: any) {
            if(!element) return
            let index = parseInt(element.getAttribute("data-index"))
            this.suggestions[index] = element
            if(this.suggestions[index])
              document.addEventListener('mousedown', this.outsideSuggestionsEvent.bind(this, index))
          }

          addMore(event: any) {
            event.preventDefault()
            let task = this.state.task
            task.addresses.push({name: "", address: "", orderId: getLastAddressId(task.addresses) + 1})
            this.setState({task})
          }

          chooseSuggestion(i:number, name: string, address: string) {
            this.addresses[i].value = address
            this.changeAddress(i, {value:address})
            this.changeAddressName(i, name)
            this.setState({suggestions: [], suggestionsFor: null})
          }

          outsideSuggestionsEvent(index: number, event: any) {
              if (this.state.suggestionsFor == index && this.suggestions[index] && !this.suggestions[index].contains(event.target) && this.state.suggestions.length > 0)
                this.setState({suggestions: [], suggestionsFor: null})
          }


          render() {
            let task = this.state.task
              return (
                <div className="addressBlock" style={{display: "block", width: "100%", float: "left"}} >
                  {task?.addresses?.sort((a:any, b:any)=> a.orderId === b.orderId ? 0 : a.orderId > b.orderId ? 1 : -1)?.map((address: any, i:number) => {
                    return (
                      <div key={i} style={{display: "inline", position: "relative", zIndex: 100-i}} >
                        <div className="addressNamePicker m left" ref={this.addSuggestionsListener} data-index={i} style={{zIndex: 10, position: "relative"}}>
                          <input type="text" name="name" autoComplete="disable_autocomplete" placeholder={"Address Name"} style={{margin: 0}} onChange={this.changeAddressName.bind(this, i, null)}  onKeyUp={this.getSuggestions.bind(this, i)} value={address.name}/>
                          {(this.state.suggestions.length>0 && this.state.suggestionsFor == i) && <ul className="suggestions">
                            {this.state.suggestions.map((suggestion: any, z:number) => {
                              return <li key={z} className="pac-item" onClick={this.chooseSuggestion.bind(this, i, suggestion.name ,suggestion.address)}>{suggestion.name}</li>
                            })}
                          </ul>}
                        </div>
                        <div className="addressPicker m right">
                          <input autoComplete="disable_autocomplete" style={{margin: 0}} type="text" placeholder={"Full Address"} onKeyUp={(e:any) => {
                            if(!e.target.value.length) this.changeAddress(i, this.addresses[i]);
                          }} ref={this.addGoogleListener} data-index={i} defaultValue={address.address}/>
                          {window.innerWidth>768 && <div className="buttons" tabIndex={-1}>
                            {task.addresses.length > 1 && i > 0 && <button tabIndex={-1} onClick={this.removeAddress.bind(this, i)}>-</button>}
                            {i+1 == task.addresses.length && <button tabIndex={-1} onClick={this.addMore.bind(this)}>+</button>}
                          </div>}
                        </div>
                        {window.innerWidth<=768 && <div className="buttonsMobile" tabIndex={-1}>
                          {task.addresses.length > 1 && i > 0 && <button tabIndex={-1} onClick={this.removeAddress.bind(this, i)}>-</button>}
                          {i+1 == task.addresses.length && <button tabIndex={-1} onClick={this.addMore.bind(this)}>+</button>}
                        </div>}
                      </div>
                    )
                  })}
                </div>
              )
            }
        }
