import * as React from "react";
import Select from "react-select";
import "./Task.css";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";
const Loader = require("react-loader-spinner").default;
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import * as Moment from "moment-timezone";
import { Task, TaskStatus } from "../../_models/Task";
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import {Addresses} from "./Addresses"
import 'rc-time-picker/assets/index.css';

import TimePicker from 'rc-time-picker';

import {
  AssignProvider,
  AssignVehicle,
  Rates,
  Expenses,
  ClientNotifications,
} from "./popups";

import "react-datepicker/dist/react-datepicker.css";
import { customSelectStyles, themeStyles } from "./themeStyles.js";
import * as controller from "./controller";

import  socket  from "../../socket";

export default class TaskPage extends React.Component<any, any> {
  handleTaskChange: any;
  handleSelectChange: any;
  sendChatNote: any;
  updateTask: any;
  pickups: any[] = [];
  dropoffs: any[] = [];
  startTime: any;
  endTime: any;

  closeTimepickers: any;
  editTask: any;
  chat: any;
  datepicker: any;
  chatLines: number;
  textBox: any;
  handleChatChange: any;
  trackUrl: any;

  handleStartTimeChange: any;
  handleEndTimeChange: any;

  constructor(props: any) {
    super(props);
    this.state = {
      taskDataOpen: false,
      typing: false,
      typingTimeout: 0,
      clients: [],
      subClients: [],
      providers: [],
      shops: [],
      cars: [],
      task: new Task(),
      startTimeClicked: false,
      endTimeClicked: false,
      startTimeOpen: false,
      endTimeOpen: false,

      loading: false,
      billablePopupOpen: false,

      //Popups
      assignProviderPopupOpen: false,
      ratesPopupOpen: false,
      assignVehiclePopupOpen: false,
      expensesPopupOpen: false,
      clientNotificationsPopupOpen: false,
      popupOpened: false,
    };
    this.editTask = controller.editTask.bind(this);
    this.updateTask = controller.updateTask.bind(this);
    this.handleTaskChange = controller.handleTaskChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.sendChatNote = controller.sendChatNote.bind(this);
    this.closeTimepickers = controller.closeTimepickers.bind(this);
    this.onDatepickerRef = this.onDatepickerRef.bind(this);
    this.handleChatChange = controller.handleChatChange.bind(this);
    this.handleStartTimeChange = controller.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = controller.handleEndTimeChange.bind(this);
  }





  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadCars();
    this.loadClients();
    this.loadProviders();
    this.loadShops();

    if (this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadTask(this.props.match.params.id);
    }

    let chatObserver = new MutationObserver((e: any) => {
      if (e[0].addedNodes && this.chat)
        this.chat.scrollTop = this.chat.scrollHeight;
    });

    chatObserver.observe(this.chat, { childList: true });

    socket.on("provider_update", (data: any) => {
      let newTask = new Task(data.task);
      let task = this.state.task;
      if (newTask.id == task.id) {
        //Update only the relevent fields from provider to the open task
        task.status = newTask.status;
        task.notes = newTask.notes;
        task.pickup = newTask.pickup
        task.dropoff = newTask.dropoff
        task.endTime = newTask.endTime;
        task.startTime = newTask.startTime;
        this.setState({ task });
      }
    });

    socket.on("task_delete", (data: any) => {
      let taskId = data.id;
      if (this.state.task.id == taskId) {
        //Closing the task
        this.props.history.push("/tasks");
      }
    });
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.match.params.id &&
      !isNaN(nextProps.match.params.id) &&
      this.state.task.id != nextProps.match.params.id
    ) {
      this.loadTask(nextProps.match.params.id);
    } else {
      this.setState({ task: new Task() });
    }
  }

  openPopup(name: string, checkDraft: boolean = false, event: any) {
    event.preventDefault();
    if (checkDraft) {
      if (!this.state.task.published && this.state.task.id) {
        toast.warn("Not avaliable in draft mode");
        return;
      }
    }
    this.setState({ [name]: true, popupOpened: true });
  }

  closePopup() {
    this.setState({
      assignProviderPopupOpen: false,
      ratesPopupOpen: false,
      assignVehiclePopupOpen: false,
      expensesPopupOpen: false,
      clientNotificationsPopupOpen: false,
      popupOpened: false,
    });
  }

  loadTask(id: any) {
    fetch("tasks/get", {
      method: "POST",
      body: JSON.stringify({ id }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let task = new Task(data.task);
        this.setState({ task }, () => {
          if (task.client) this.loadSubClients(task.client.id);
        });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadCars() {
    fetch("cars/getAll", {
      method: "POST",
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let cars = data.cars;
        cars = cars.map((car: any) => {
          return { value: car.id, label: car.name, type: car.type };
        });
        this.setState({ cars });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadShops() {
    fetch("shops/getAll", {
      method: "POST",
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let shops = data.shops;
        shops = shops.map((shop: any) => {
          return { value: shop.id, label: shop.name };
        });
        this.setState({ shops });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadClients() {
    fetch("clients/getAll", {
      method: "POST",
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let clients = data.clients;
        clients = clients.map((client: any) => {
          return {
            value: client.id,
            label: client.name,
            phone: client.phone,
            email: client.email,
          };
        });
        this.setState({ clients: clients });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadProviders() {
    fetch("providers/getAll", {
      method: "POST",
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let providers = data.providers;
        providers = providers.map((provider: any) => {
          return { value: provider.id, label: provider.name };
        });
        this.setState({ providers: providers });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  loadSubClients(clientId: number) {
    fetch("clients/get", {
      method: "POST",
      body: JSON.stringify({ id: clientId }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let subClients: any[] = [];

        data.client.projects.map((project: any) => {
          subClients.push({
            project: true,
            value: project.id,
            label: project.name,
          });
        });

        data.client.subClients.map((subClient: any) => {
          subClients.push({
            value: subClient.id,
            label: subClient.name,
            phone: subClient.phone,
            email: subClient.email,
          });
        });

        this.setState({ subClients });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  componentWillMount() {
    socket.connect();
  }

  onDatepickerRef(el: any) {
    if (el && el.input) {
      this.datepicker = el;
      if (window.innerWidth <= 768) this.datepicker.input.readOnly = true;
    }
  }

  componentWillUnmount() {
    socket.removeAllListeners();
    socket.disconnect();
  }

  unassignProvider(event: any) {
    event.preventDefault();
    let task = this.state.task;
    if (
      task.status == TaskStatus.completed ||
      task.status == TaskStatus.active
    ) {
      toast.warn(
        "It is not possible to unassign a provider from an active/completed task"
      );
      return;
    }
    task.provider = null;
    task.subProvider = null;
    task.updated = false;
    task.status = TaskStatus.unassigned;
    task.trackUrl = null;
    this.setState({ task });
  }

  copyToClipboard(event: any) {
    event.preventDefault();
    let el = this.trackUrl;
    el.style.display = "block";
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      if (!sel) return;
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      el.select();
    }
    document.execCommand("copy");
    el.style.display = "none";
    toast.success("URL copied");
  }

  public render() {
    let task = this.state.task;
    let Status = controller.RenderStatus;
    let Chat = controller.RenderChat;
    let client = task && task.client;
    let subClient = task && task.subClient;
    let project = task && task.project;
    let provider = task && task.provider;
    let providerButton;
    if (provider) {
      providerButton = (
        <button
          className="primary-btn left"
          onClick={this.unassignProvider.bind(this)}
        >
          Unassign{" "}
          {task.subProvider ? task.subProvider.name : task.provider.name}
        </button>
      );
    } else {
      providerButton = (
        <button
          className="primary-btn left"
          onClick={this.openPopup.bind(this, "assignProviderPopupOpen", true)}
        >
          Assign Provider
        </button>
      );
    }
    return (
      <div
        id="create-task-root"
        style={{
          overflow:
            window.innerWidth <= 768 && this.state.popupOpened ? "hidden" : "",
        }}
      >
        <Prompt
          when={task && !task.updated}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {this.state.loading && (
          <div className="loadingDiv">
            <Loader type="Oval" color="#e13c34" height="100" width="100" />
          </div>
        )}
        {this.state.billablePopupOpen && (
          <div className="billablePopup">
            <div className="inner">
              <i
                onClick={() => {
                  this.setState({ billablePopupOpen: false });
                }}
                className="fas fa-times"
              ></i>
              <div className="buttons">
                <button
                  tabIndex={-1}
                  className="primary-btn"
                  onClick={controller.exportTask.bind(this, true)}
                >
                  Billable
                </button>
                <button
                  tabIndex={-1}
                  className="primary-btn"
                  onClick={controller.exportTask.bind(this, false)}
                >
                  Not Billable
                </button>
              </div>
            </div>
          </div>
        )}
        <a
          onClick={() => {
            let path = task.id
              ? this.props.match.path.replace("/:id", "")
              : this.props.match.path.replace("/new", "");
            this.props.history.push(path + this.props.location.search);
          }}
        >
          <i className="fas fa-times"></i>
        </a>
        {task && (
          <form
            className="data"
            style={{
              overflow:
                window.innerWidth <= 768 && this.state.popupOpened
                  ? "hidden"
                  : "",
            }}
          >
            {task.id && task.published && (
              <Link
                to={this.props.match.path.replace(
                  ":id",
                  task.id + "/data/office" + this.props.location.search
                )}
              >
                <button className="taskData">Task Data</button>
              </Link>
            )}
            {task.trackUrl && (
              <button
                className="taskData"
                style={{ zIndex: 10, position: "relative" }}
                onClick={this.copyToClipboard.bind(this)}
              >
                Copy Provider Link
              </button>
            )}
            {task.trackUrl && (
              <input
                style={{
                  zIndex: 1,
                  display: "none",
                  position: "absolute",
                  top: "-2000px",
                  left: "-1000px",
                }}
                ref={(el) => (this.trackUrl = el)}
                value={task.trackUrl}
                readOnly={true}
                contentEditable={false}
              />
            )}

            {!task.id && !task.published && (
              <button onClick={this.updateTask} className="taskData grey">
                Save Draft
              </button>
            )}
            {task.id && !task.published && (
              <button
                onClick={controller.publishTask.bind(this, false)}
                className="taskData green"
              >
                Publish this draft
              </button>
            )}
            <h4 className="taskStatus">
              Status:{" "}
              {
                <Status
                  toggleStatus={controller.toggleStatus.bind(this)}
                  status={task.status}
                />
              }
            </h4>
            {task.id && <h4 className="taskStatus">Task ID: {task.id}</h4>}
            <div className="clearfix"></div>

            <div className="third" style={{ zIndex: 20000 }}>
              <DatePicker
                selected={Moment(task.date, "MM/DD/YYYY").toDate()}
                onChange={controller.handleDateChange.bind(this)}
                autoComplete="disable_autocomplete"
                name="date"
                ref={(el: any) => this.onDatepickerRef(el)}
              />
            </div>

            <div
              className="timerPicker third"
            >

              <TimePicker
                key={task.startTime}
                name="startTime"
                placeholder="Start Time"
                showSecond={false}
                defaultValue={task.startTime? Moment(task.startTime, "hh:mm A") : undefined}
                onChange={this.handleStartTimeChange}
                format="hh:mm A"
                use12Hours={true}
                inputReadOnly={true}
                open={this.state.startTimeOpen}
                onOpen={() => {
                  this.setState({ startTimeOpen: true });
                }}
                onClose={() => {
                  this.setState({ startTimeOpen: true });
                }}
                addon={(panel:any) => {
                  return (
                    <button
                      style={{ width: "100%", height: "30px", cursor: "pointer", backgroundColor: "#e13c34", color: "#fff", fontWeight: "bold" }}
                      onClick={() => {
                        this.setState({ startTimeOpen: false });
                      }}
                    >
                      Done
                    </button>
                  );
                }}
              />

            </div>
            <div
              className="timerPicker third right"
            >
            <TimePicker
                key={task.endTime}
                name="endTime"
                placeholder="End Time"
                showSecond={false}
                defaultValue={task.endTime? Moment(task.endTime, "hh:mm A") : undefined}
                onChange={this.handleEndTimeChange}
                format="hh:mm A"
                use12Hours={true}
                inputReadOnly={true}
                open={this.state.endTimeOpen}
                onOpen={() => {
                  this.setState({ endTimeOpen: true });
                }}
                onClose={() => {
                  this.setState({ endTimeOpen: true });
                }}
                addon={(panel:any) => {
                  return (
                    <button
                      style={{ width: "100%", height: "30px", cursor: "pointer", backgroundColor: "#e13c34", color: "#fff", fontWeight: "bold" }}
                      onClick={() => {
                        this.setState({ endTimeOpen: false });
                      }}
                    >
                      Done
                    </button>
                  );
                }}

              />
            </div>



            {/* <DndProvider backend={HTML5Backend}>
                <Addresses
                  //controller={controller}
                  //task={task}
                  taskAddress={[{name:"boaz", id:1}, {name:"aiden", id:2}]}
                  changeAddress={controller.changeAddress.bind(this)}
                  changeAddressName={controller.changeAddressName.bind(this)}
                />
            </DndProvider> */}

              <controller.RenderAddresses
                  controller={controller}
                  task={task}
                  changeAddress={controller.changeAddress.bind(this)}
                  changeAddressName={controller.changeAddressName.bind(this)}
                />
            <div className="clearfix"></div>
            <input
              type="text"
              name="description"
              autoComplete="disable_autocomplete"
              placeholder="Task Description"
              className="xl left"
              onChange={this.handleTaskChange}
              defaultValue={task.description}
            />
            <input
              type="text"
              name="po"
              placeholder="P.O"
              autoComplete="disable_autocomplete"
              className="xm right"
              onChange={this.handleTaskChange}
              defaultValue={task.po}
            />
            <textarea
              style={{ 
                height: "100px",
                textIndent: 0,
                padding: "15px"
              }}   
              name="backOffice"
              placeholder="Back Office Notes"
              autoComplete="disable_autocomplete"
              className="full"
              onChange={this.handleTaskChange}
              defaultValue={task.backOffice}
            ></textarea>
            <div className="third selectBox">
              <Select
                styles={customSelectStyles}
                value={client && { value: client.id, label: client.name }}
                onChange={controller.handleSelectChange.bind(this)}
                options={this.state.clients}
                theme={themeStyles}
                className={
                  this.state.subClients.length > 0
                    ? "multiSelect split_l"
                    : "multiSelect"
                }
                classNamePrefix="react-select"
                menuPlacement={"bottom"}
                placeholder={"Select Client"}
              />
              {this.state.subClients.length > 0 && (
                <Select
                  styles={customSelectStyles}
                  value={
                    (project && { value: project.id, label: project.name }) ||
                    (subClient && {
                      value: subClient.id,
                      label: subClient.name,
                    })
                  }
                  onChange={controller.handleSelectSubChange.bind(this)}
                  options={this.state.subClients}
                  theme={themeStyles}
                  className="multiSelect split_r"
                  classNamePrefix="react-select"
                  menuPlacement={"bottom"}
                  placeholder={"Sub Client/Project"}
                />
              )}
            </div>

            <button
              className={
                task.notifications && task.notifications.length > 0
                  ? "second-btn focused"
                  : "second-btn"
              }
              onClick={this.openPopup.bind(
                this,
                "clientNotificationsPopupOpen",
                false
              )}
            >
              Set Client Notifications
            </button>
            {this.state.clientNotificationsPopupOpen && (
              <ClientNotifications closePopup={this.closePopup} task={task} />
            )}

            <button
              className={
                (task.clientHourRate ||
                  task.clientFlatRate ||
                  task.clientPRate) &&
                (task.providerHourRate ||
                  task.providerFlatRate ||
                  task.providerPRate)
                  ? "second-btn right focused"
                  : "second-btn right"
              }
              onClick={this.openPopup.bind(this, "ratesPopupOpen", false)}
            >
              Set Rates
            </button>
            {this.state.ratesPopupOpen && (
              <Rates closePopup={this.closePopup} task={task} />
            )}

            {providerButton}
            {this.state.assignProviderPopupOpen && (
              <AssignProvider
                closePopup={this.closePopup}
                providers={this.state.providers}
                task={task}
              />
            )}

            <button
              className={task.car ? "primary-btn focused" : "primary-btn"}
              onClick={this.openPopup.bind(
                this,
                "assignVehiclePopupOpen",
                false
              )}
            >
              Assign Vehicle
            </button>
            {this.state.assignVehiclePopupOpen && (
              <AssignVehicle
                closePopup={this.closePopup}
                task={task}
                cars={this.state.cars}
              />
            )}

            <button
              className={
                task.expenses && task.expenses.length > 0
                  ? "primary-btn right focused"
                  : "primary-btn right"
              }
              onClick={this.openPopup.bind(this, "expensesPopupOpen", false)}
            >
              Expenses
            </button>
            {this.state.expensesPopupOpen && (
              <Expenses
                closePopup={this.closePopup}
                task={task}
                shops={this.state.shops}
              />
            )}

            <div id="chat" className="chat full" ref={(el) => (this.chat = el)}>
              {<Chat task={task} userMode={true} />}
            </div>
            <div className="newMsgContainer full">
              <textarea
                name="newMessage"
                placeholder="Type note..."
                onChange={this.handleChatChange.bind(this, 150)}
                onKeyDown={controller.keyPress.bind(this, null, null, 150)}
                ref={(e: any) => {
                  this.textBox = e;
                }}
                style={{ height: "50px" }}
              ></textarea>

              <span
                onClick={controller.sendChatNote.bind(this, null, null)}
                className="sendMsg"
              >
                Send
              </span>
            </div>

            {!task.exported && (
              <button
                tabIndex={-1}
                className={
                  "update " + (task.id && task.updated ? "updated" : "")
                }
                onClick={
                  task.id
                    ? this.updateTask
                    : controller.publishTask.bind(this, true)
                }
                disabled={task.updated}
              >
                {task.id ? (task.updated ? "Updated" : "Update") : "Publish"}
              </button>
            )}
            {task.published && task.id && (
              <div>
                {task.status != TaskStatus.completed && (
                  <button
                    className="export"
                    onClick={controller.completeTask.bind(this)}
                  >
                    Completed
                  </button>
                )}
                <button
                  className="second-btn"
                  onClick={controller.duplicateTask.bind(this)}
                >
                  Duplicate
                </button>
                {task.status != TaskStatus.completed && (
                  <button
                    className="primary-btn"
                    onClick={controller.cancelTask.bind(this)}
                  >
                    Canceled
                  </button>
                )}
                {task.status == TaskStatus.completed && !task.archived && (
                  <button
                    onClick={controller.archiveTask.bind(this)}
                    className="export"
                  >
                    Archive & Export Later
                  </button>
                )}
                {task.status == TaskStatus.completed && !task.exported && (
                  <button
                    onClick={(event: any) => {
                      event.preventDefault();
                      this.setState({ billablePopupOpen: true });
                    }}
                    className="export"
                  >
                    Export to Quickbooks
                  </button>
                )}
              </div>
            )}
          </form>
        )}
      </div>
    );
  }
}
