import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Providers from '../providers/Providers';
import ProviderPage from '../provider/Provider';

export default class ProvidersRouter extends React.Component<any, any> {

  public render() {
    return (
      <div id="providers-router-root">
        <div className="buttonsHeader">
          <Link to="/providers/new"><button>Add a Provider</button></Link>
          <Link to="/providers/new"><button>Export Providers</button></Link>
        </div>

        <Switch>
          <Route exact path={`/providers`} component={Providers} />
          <Route exact path={`/providers/new`} component={ProviderPage} />
          <Route exact path={`/providers/:id`} component={ProviderPage} />
        </Switch>

      </div>
    );
  }
}
