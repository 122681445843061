import * as React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import {Car} from '../../_models/Car'
import {customSelectStyles} from './selectStyles.js'
import * as controller from './controller'
const Loader = require('react-loader-spinner').default;
import { toast } from 'react-toastify';

export default class CarPage extends React.Component<any, any> {

  handleCarChange: any
  handleSelectChange: any
  updateCar:any

  constructor(props: any) {
    super(props);
    this.state = {
      car: new Car(),
      updating: false
    }
    this.updateCar = controller.updateCar.bind(this)
    this.handleCarChange = controller.handleCarChange.bind(this)
  }



  componentDidMount() {
    if(this.props.match.params.id && !isNaN(this.props.match.params.id)) {
      this.loadCar(this.props.match.params.id)
    }

  }

  componentWillReceiveProps(nextProps: any) {
    if(nextProps.match.params.id
      && !isNaN(nextProps.match.params.id)
      && this.state.car.id != nextProps.match.params.id) {
      this.loadCar(nextProps.match.params.id)
    } else {
      this.setState({car: new Car()})
    }

  }



  loadCar(id:any) {
    fetch('cars/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let car = new Car(data.car)
        this.setState({car})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  public render() {
    let car = this.state.car
    return (
      <div id="edit-account-root">
      {this.state.updating && <div className="loadingDiv">
        <Loader
           type="Oval"
           color="#e13c34"
           height="100"
           width="100"
        />
      </div>}
          <Link to="/cars"><i className="fas fa-times"></i></Link>
          <form className="data">
            <input className="third" onChange={this.handleCarChange} defaultValue={car.name} name="name" placeholder="Vehicle's Model" />
            <div className="third">
              <Select
                styles={customSelectStyles}
                value={car && {value: car.type, label: car.type}}
                onChange={controller.handleSelectChange.bind(this)}
                options={[{value: 'owner', label: 'owner'}, {value: 'company', label: 'company'}]}
                menuPlacement={"bottom"}
                placeholder={"Select a vehicle type"}
              />
            </div>
            <button className={"update "+((car.id && car.updated)? "updated" : "")} onClick={this.updateCar} disabled={car.updated || this.state.updating}>
              {this.state.updating? "Updating" :
                car.id? car.updated? "Updated" :
                "Update" :
                "Save"}
            </button>
          </form>
        </div>
    )
  }
}
