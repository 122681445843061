import {Provider} from '../../_models/Provider'
import { toast } from 'react-toastify';

  export function updateProvider(this:any, event:any) {
    event.preventDefault()
    this.setState({updating: true})
    fetch('providers/edit', {
      method: 'POST',
      body: JSON.stringify(this.state.provider)
    }).then(results => {
      return results.json();
    }).then(data => {
        let provider = new Provider(data.provider)
        provider.updated = true
        if(!this.state.provider.id) {
          //New provider, go back
          this.props.history.push("/providers")
          toast.success("Provider created successfuly")
        } else {
          //It's an update
          toast.success("Provider updated successfuly")
          this.setState({provider, updating: false})
        }
    }).catch(err => {
      toast.error(err.message);
      this.setState({updating: false})
    })


  }

  export function removeProvider(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    fetch('providers/delete', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(() => {
      let providers = this.state.providers
      providers.splice(index, 1)
      toast.success("Provider removed successfuly")
      this.setState({providers})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  export function removeSubProvider(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let provider = this.state.provider
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    if(id) {
      fetch('providers/deleteSub', {
        method: 'POST',
        body: JSON.stringify({id})
      }).then(() => {
        provider.subProviders.splice(index, 1)
        toast.success("Provider removed successfuly")
        this.setState({provider})
      }).catch(err => {
        toast.error(err.message);
      })
    } else {
      provider.subProviders.splice(index, 1)
      toast.success("Provider removed successfuly")
      this.setState({provider})
    }


  }


  export function handleSelectChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      } else {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      }
    });
  }




  export function handleProviderChange(this:any, event: any) {
    let provider = this.state.provider
    provider[event.target.name] = event.target.value
    provider.updated = false
    this.setState({provider});
  }

  export function editPhone(this:any, phoneNum:any) {
    let provider = this.state.provider
    provider.phone = phoneNum || ""
    provider.updated = false
    this.setState({provider});
  }


  export function handleSubProviderChange(this:any, index: number, event: any) {
    let provider = this.state.provider
    provider.subProviders[index][event.target.name] = event.target.value
    provider.updated = false
    this.setState({provider});
  }

  export function editSubPhone(this:any, index: number, phoneNum:any) {
    let provider = this.state.provider
    provider.subProviders[index].phone = phoneNum || ""
    provider.updated = false
    this.setState({provider});
  }
