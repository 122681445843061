import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import * as controller from '../provider/controller'

export default class Providers extends React.Component<any, any> {
  searchString: any

  constructor(props: any) {
    super(props);
    this.state = {
      providers: [],
      providersFiltered: []
    }
  }

  search() {
      let providersFiltered = this.state.providersFiltered
      providersFiltered = this.state.providers.filter((provider: any) => {
        const foundName = provider.name?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        const foundPhone = provider.phone?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        const foundEmail = provider.email?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
        return foundName || foundPhone || foundEmail
      })
      //each provider has subProviders, so search by their names too
      providersFiltered = providersFiltered.concat(this.state.providers.filter((provider: any) => {
        return provider.subProviders?.some((subProvider: any) => {
          const foundName = subProvider.name?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          const foundPhone = subProvider.phone?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          const foundEmail = subProvider.email?.toLowerCase().includes(this.searchString.value.trim().toLowerCase())
          return foundName || foundPhone || foundEmail
        })
      }))
      this.setState({providersFiltered})
  }

  loadProviders() {
    fetch('providers/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let providers = data.providers
        this.setState({providers, providersFiltered: providers})
    }).catch(err => {
      //alert(err.message);
    })
  }

  componentDidMount() {

  }

  componentWillMount() {
    this.loadProviders()
  }

  componentWillUnmount() {
  }


  public render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'E-Mail',
      accessor: 'email'
    }, {
      Header: 'Phone',
      accessor: 'phone'
    },
    {
      Header: 'Rating',
      accessor: 'rating'
    }, {
      id: 'settings',
      Header: 'Settings',
      sortable: false,
      maxWidth: 100,
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>
        <Link to={"/providers/" + row.value.id}><i className="fas fa-edit"></i></Link>
        <i onClick={controller.removeProvider.bind(this, row.value.id, row.index)} className="fas fa-trash-alt"></i>
      </div>
    }]

    return (
      <div id="users-root">
        <input className="search" type="text" placeholder="Search provider by name" onChange={this.search.bind(this)} ref={(searchString:any) => this.searchString = searchString} />

        <div className="board">
          <ReactTable
            data={this.state.providersFiltered}
            columns={columns}
            noDataText="No providers found"
            pageSizeOptions={[100,300,500,1000]}
            defaultPageSize={100}
            style={{
              height: "calc(100vh - 120px)",
              textAlign: "center",
              fontSize: "1em",
              position: "centner",
              right: "0",
              left: "0",
              margin: "0 auto"
            }}
            className="-striped -highlight reactTable"
          />
        </div>
      </div>
    )
  }

}
