import * as Moment from 'moment-timezone'
import {TaskStatus} from "./Task"

export class ClientTask {
  public name: string = "clientTask"
  public id?: number
  public status: string
  public date: string
  public startTime: string
  public endTime: string
  public layouts:  boolean
  public description: string
  public locations: any[]
  public signatures: any[]
  public expenses: any[]
  public images: any[]
  public taskId?: number

  constructor(task:any) {
    this.id = task.id || null
    this.taskId = task.taskId || null
    this.status = task.status || TaskStatus.unassigned
    this.date =  (task.date && task.startTime)? Moment.tz(task.date, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY") : Moment().tz(process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY")
    this.startTime = task.startTime ? Moment.tz(task.startTime, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A") : ""
    this.endTime = task.endTime ? Moment.tz(task.endTime, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A") : ""
    this.layouts = task.layouts==undefined? ((task.taskExpenses && task.taskExpenses.length>0) || (task.clientTaskExpenses && task.clientTaskExpenses.length>0))? true : false : task.layouts
    this.description = task.description
    this.locations = task.taskLocations || task.clientTaskLocations || []
    this.signatures = task.taskSignatures || task.clientTaskSignatures || []
    this.expenses = task.taskExpenses || task.clientTaskExpenses || []
    this.images = task.taskImages || task.clientTaskImages || []
  }
}
