import * as React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { customSelectStyles, themeStyles } from "./themeStyles.js";
import "./popup.css";
const Loader = require("react-loader-spinner").default;

export class Expenses extends React.Component<any, any> {
  closePopup: any;
  imageFile: any;
  constructor(props: any) {
    super(props);
    this.state = {
      newExpense: {
        id: null,
        name: "",
        description: "",
        amount: "",
        method: null,
        payer: null,
        shop: null,
        billable: null,
        forWhom: null,
        image: null,
      },
      loading: false,
      task: null,
      methods: require(process.env.REACT_APP_DOMAIN
        ? "../../../assets/methods"
        : "../../../assets/methods_dev"),
      shops: [],
      payers: [
        { value: "admin", label: "ServeTie" },
        { value: "provider", label: "Personal" },
      ],
      forWhoms: [
        { value: "company", label: "Company" },
        { value: "client", label: "Client" },
      ],
      billables: [
        { value: true, label: "Billable" },
        { value: false, label: "Not Billable" },
      ],
    };
    this.closePopup = this.props.closePopup;
  }

  componentDidMount() {
    this.setState({
      task: this.props.task,
      shops: this.props.shops,
    });
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      task: nextProps.task,
      shops: nextProps.shops,
    });
  }

  chooseSelect(name: string, selectedOption: any) {
    let expense = this.state.newExpense;
    expense[name] = selectedOption;
    this.setState({ newExpense: expense });
  }

  editExpense(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let expense = this.state.newExpense;
    expense[name] = value;
    this.setState({ newExpense: expense });
  }

  viewExpense(expense: any) {
    let newExpense = this.collpaseExpense(expense);
    this.setState({ newExpense });
  }

  collpaseExpense(expense: any) {
    let newExpense = Object.assign({}, expense);
    let payerLabel = expense.payer == "admin" ? "ServeTie" : "Personal";
    let forWhomLabel = expense.payer == "company" ? "Company" : "Client";
    let billableLabel = expense.billable == true ? "Billable" : "Not Billable";
    let method = this.state.methods.filter((method: any) => {
      return method.value == expense.method;
    });
    newExpense.payer = expense.payer
      ? { value: expense.payer, label: payerLabel }
      : null;
    newExpense.forWhom = expense.forWhom
      ? { value: expense.forWhom, label: forWhomLabel }
      : null;
    newExpense.method = expense.method
      ? expense.method.value
        ? expense.method
        : method[0]
      : null;
    newExpense.billable = expense.billable.value
      ? expense.billable
      : { value: expense.billable, label: billableLabel };
    newExpense.shop = expense.shop
      ? {
          value: expense.shop.id || expense.shop.value,
          label: expense.shop.name || expense.shop.label,
        }
      : null;
    newExpense.image = expense.image;
    return newExpense;
  }

  decollpaseExpense(expense: any) {
    let newExpense = Object.assign({}, expense);
    newExpense.payer = expense.payer ? expense.payer.value : null;
    newExpense.forWhom = expense.forWhom ? expense.forWhom.value : null;
    newExpense.method = expense.method ? expense.method.value : null;
    newExpense.billable = expense.billable.value;
    newExpense.shop = expense.shop || null;
    newExpense.image = expense.image || null;
    return newExpense;
  }

  submitExpense(event: any) {
    event.preventDefault();
    let task = this.state.task;
    let expense = this.state.newExpense;

    if (!expense.id) {
      task.expenses.push(this.decollpaseExpense(expense));
    } else {
      //We replace this expense
      task.expenses = task.expenses.map((taskExpense: any) => {
        if (taskExpense.id == expense.id) {
          return this.decollpaseExpense(expense);
        } else {
          return taskExpense;
        }
      });
    }
    task.updated = false;
    let rawExpense = {
      name: "",
      description: "",
      amount: "",
      method: null,
      shop: null,
      payer: null,
      forWhom: null,
      billable: null,
      id: null,
      image: null,
    };
    console.log(task.expenses);
    this.setState({ task, newExpense: rawExpense });
  }

  deleteExpense(index: number, event: any) {
    event.preventDefault();
    let task = this.state.task;
    let expense = task.expenses[index];

    let confirmed = confirm("Are you sure?");
    if (!confirmed) return;

    let rawExpense = {
      name: "",
      description: "",
      amount: "",
      method: null,
      shop: null,
      payer: null,
      forWhom: null,
      billable: null,
      id: null,
      image: null,
    };

    if (expense.id) {
      fetch("tasks/deleteExpense", {
        method: "POST",
        body: JSON.stringify({ id: expense.id }),
      })
        .then((data) => {
          task.expenses.splice(index, 1);
          this.setState({ task: task });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      task.updated = false;
      task.expenses.splice(index, 1);
      this.setState({ task, newExpense: rawExpense });
    }
  }

  getBase64(file: any) {
    return new Promise((resolve: any, reject: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  imageFileSelected() {
    let file = this.imageFile.files[0];
    if (file && file.size / 1024 / 1024 > 4) {
      toast.error("Too big. 4MB limit");
      return;
    }
    if (!file) return;
    this.setState({ loading: true });
    let newExpense = this.state.newExpense;
    this.getBase64(file).then((base64File: string) => {
      newExpense.image = base64File;
      this.setState({ loading: false, newExpense });
    });
  }

  removeImage() {
    this.imageFile.value = null;
    let newExpense = this.state.newExpense;
    newExpense.image = null;
    this.setState({ newExpense });
  }

  public render() {
    let total = 0;
    let task = this.state.task;
    return (
      <div id="popup-root" style={{ height: "680px" }}>
        {this.state.loading && (
          <div className="loadingDiv">
            <Loader type="Oval" color="#e13c34" height="100" width="100" />
          </div>
        )}
        <i onClick={this.closePopup} className="fas fa-times"></i>

        <div className="container expenses">
          <div className="right">
            <h2>List of Expenses:</h2>
            <ul>
              {task &&
                task.expenses.map((expense: any, i: number) => {
                  total += parseInt(expense.amount);
                  return (
                    <li key={i}>
                      <span className="red">
                        {expense.name && expense.name.length > 0
                          ? expense.name
                          : "No name"}
                      </span>
                      <span className="red">${expense.amount}</span>
                      <span className="red">
                        {expense.payer
                          ? expense.payer == "admin"
                            ? "ServeTie"
                            : "Personal"
                          : "No type"}
                      </span>
                      <span className="red">
                        {expense.billable ? "Billable" : "Not Billable"}
                      </span>
                      <span
                        style={{ borderRight: "1px solid #000" }}
                        onClick={this.viewExpense.bind(this, expense)}
                      >
                        View
                      </span>
                      <span onClick={this.deleteExpense.bind(this, i)}>
                        <i
                          className="fas fa-trash"
                          style={{ color: "red" }}
                        ></i>
                      </span>
                      <div className="clearfix"></div>
                    </li>
                  );
                })}
            </ul>
            <h4>Total: ${total}</h4>
          </div>

          <div className="left">
            <h2>Enter New Expense:</h2>
            <div className="input">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.newExpense.name || ""}
                onChange={this.editExpense.bind(this)}
              />
              <input
                type="text"
                name="description"
                placeholder="Description"
                value={this.state.newExpense.description || ""}
                onChange={this.editExpense.bind(this)}
              />
              <input
                type="text"
                name="amount"
                placeholder="Amount"
                value={this.state.newExpense.amount}
                onChange={this.editExpense.bind(this)}
              />
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={this.state.newExpense.forWhom}
                  onChange={this.chooseSelect.bind(this, "forWhom")}
                  options={this.state.forWhoms}
                  menuPlacement={"bottom"}
                  placeholder={"Layout type"}
                />
              </div>
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={this.state.newExpense.payer}
                  onChange={this.chooseSelect.bind(this, "payer")}
                  options={this.state.payers}
                  menuPlacement={"bottom"}
                  placeholder={"Payment Method used"}
                />
              </div>
              {this.state.newExpense.payer &&
                this.state.newExpense.payer.value == "admin" && (
                  <div className="selectContainer">
                    <Select
                      theme={themeStyles}
                      styles={customSelectStyles}
                      className="multiSelect"
                      classNamePrefix="react-select"
                      value={this.state.newExpense.shop}
                      onChange={this.chooseSelect.bind(this, "shop")}
                      options={this.state.shops}
                      menuPlacement={"bottom"}
                      placeholder={"Connect it with a shop"}
                    />
                  </div>
                )}
              {this.state.newExpense.payer &&
                this.state.newExpense.payer.value == "admin" && (
                  <div className="selectContainer">
                    <Select
                      theme={themeStyles}
                      styles={customSelectStyles}
                      className="multiSelect"
                      classNamePrefix="react-select"
                      value={this.state.newExpense.method}
                      onChange={this.chooseSelect.bind(this, "method")}
                      options={this.state.methods}
                      menuPlacement={"bottom"}
                      placeholder={"Method used"}
                    />
                  </div>
                )}
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={this.state.newExpense.billable}
                  onChange={this.chooseSelect.bind(this, "billable")}
                  options={this.state.billables}
                  menuPlacement={"bottom"}
                  placeholder={"Billable or not?"}
                />
              </div>
              {!this.state.newExpense.image && (
                <a
                  onClick={() => {
                    this.imageFile.click();
                  }}
                >
                  Upload Image
                </a>
              )}
              {this.state.newExpense.image && (
                <a onClick={this.removeImage.bind(this)}>Remove Image</a>
              )}
              <input
                onChange={this.imageFileSelected.bind(this)}
                style={{ display: "none" }}
                type="file"
                ref={(input) => (this.imageFile = input)}
              />
              <button
                className="primary-btn"
                onClick={this.submitExpense.bind(this)}
              >
                Submit
              </button>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
