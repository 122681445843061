import {Car} from '../../_models/Car'
import { toast } from 'react-toastify';

  export function updateCar(this:any, event:any) {
    event.preventDefault()
    this.setState({updating: true})
    fetch('cars/edit', {
      method: 'POST',
      body: JSON.stringify(this.state.car)
    }).then(results => {
      return results.json();
    }).then(data => {
        let car = new Car(data.car)
        car.updated = true
        if(!this.state.car.id) {
          //New car, go back
          this.props.history.push("/cars")
          toast.success("Vehicle created successfuly")
        } else {
          //It's an update
          toast.success("Vehicle updated successfuly")
          this.setState({car, updating: false})
        }
    }).catch(err => {
      toast.error(err.message);
      this.setState({updating: false})
    })


  }

  export function removeCar(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    fetch('cars/delete', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(() => {
      let cars = this.state.cars
      cars.splice(index, 1)
      toast.success("Vehicle removed successfuly")
      this.setState({cars})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  export function handleSelectChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      let car = this.state.car
      car.updated = false
      car.type = selectedOption.value
      this.setState({car})
    });
  }




  export function handleCarChange(this:any, event: any) {
    let car = this.state.car
    car[event.target.name] = event.target.value
    car.updated = false
    this.setState({car});
  }
