import {Task} from '../../_models/Task'
import {ClientTask} from '../../_models/ClientTask'
import { toast } from 'react-toastify';

export function loadTask(this: any, id:any, officeTask: boolean) {
    fetch('tasks/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let task = new Task(data.task)
        let clientTask = data.task.clientTask
        if(!clientTask) {
          //create new one from the office task
          clientTask = new ClientTask(data.task)
          delete clientTask.id //it's a new clientTask
          clientTask.locations = clientTask.locations.map((location: any) => {
            let tLocation = Object.assign({}, location)
            delete tLocation.id
            return tLocation
          })
          clientTask.signatures = clientTask.signatures.map((signature: any) => {
            let tSignature = Object.assign({}, signature)
            delete tSignature.id
            return tSignature
          })
          clientTask.images = clientTask.images.map((image: any) => {
            let tImage = Object.assign({}, image)
            delete tImage.id
            return tImage
          })
          clientTask.expenses = clientTask.expenses.map((expense: any) => {
            let tExpense = Object.assign({}, expense)
            delete tExpense.id
            return tExpense
          })
        } else {
          clientTask = new ClientTask(data.task.clientTask)
        }
        this.setState({task: task, clientTask: clientTask})
        let taskForMap = officeTask? task : clientTask
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, taskForMap);
    }).catch(err => {
      toast.error(err.message);
    })
  }

  export function loadTaskFromToken(this: any, token:string) {
      fetch('public/getClientTask', {
        method: 'POST',
        body: JSON.stringify({token})
      }).then(results => {
        return results.json();
      }).then(data => {
          let clientTask = new ClientTask(data.clientTask)
          this.setState({clientTask: clientTask})
          this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, clientTask);
      }).catch(err => {
        toast.error(err.message);
      })
    }

export function calculateAndDisplayRoute(this: any, directionsService: any, directionsDisplay: any, task: any) {
   if(!task.locations || task.locations.length < 1) {
     this.directionsDisplay.setMap(null);
     if(this.marker) this.marker.setMap(null)
     return
   } else if(task.locations.length < 2 && task.locations.length > 0) {
     this.directionsDisplay.setMap(null);
     this.marker = new window.google.maps.Marker({
             position: {lat: task.locations[0].lat, lng: task.locations[0].lng},
             map: this.map
           });
    this.map.setCenter(this.marker.getPosition())
    this.map.setZoom(12)
    return
   }

   if(this.marker) this.marker.setMap(null)
   this.directionsDisplay.setMap(this.map);

   var waypts: any[] = [];
   for (var i = 1; i < task.locations.length-1; i++) {
     waypts.push({
       location: task.locations[i].address,
       stopover: true
     });
   }

   directionsService.route({
     origin: task.locations[0].address,
     destination: task.locations[task.locations.length-1].address,
     waypoints: waypts,
     optimizeWaypoints: false,
     travelMode: 'DRIVING'
   }, (response: any, status: any) => {
     if (status === 'OK') {
       directionsDisplay.setDirections(response);
       this.setState({[task.name]: task})
     } else {
       toast.error('Directions request failed due to ' + status);
     }
   });

}

export function loadMap(this: any, officeTask: boolean) {
  let google = window.google
  this.directionsService = new google.maps.DirectionsService;
  this.directionsDisplay = new google.maps.DirectionsRenderer;
  this.map = new google.maps.Map(document.getElementById('map'), {
    zoom: 8,
    mapTypeControl: false,
    streetViewControl: false,
    center: {lat: 40.712776, lng: -74.005974} //New York Center
  });

  if(this.props.match) {
    if(this.props.match.params.id) {
      this.loadTask(this.props.match.params.id, officeTask)
    } else if(this.props.match.params.token) {
      this.loadTaskFromToken(this.props.match.params.token)
    }
  }
}


export function changeAddress(this:any, index:number, taskName: string) {
  if(this.state.clientView) return;
  const task = this.state[taskName]
  this.activeIndex = index
  this.setState({addressPopupOpened: true}, () => {
    this.address.value = task.locations[index].address
    let autocomplete = new window.google.maps.places.Autocomplete(this.address)
    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace()
        task.locations[index].lat = place.geometry.location.lat()
        task.locations[index].lng = place.geometry.location.lng()
        task.locations[index].address = place.formatted_address
        this.setState({[taskName]: task, addressPopupOpened: false},() => {
          this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, this.state[taskName]);
        })
    });
  })
}
