import * as React from "react";
import { useEffect, useState } from "react";
import { ServerTGeo, TGeo } from "../../../_models/Task";
import { toast } from "react-toastify";
import * as moment from "moment";
import recIcon from "./rec.png";

const google = window.google;

type TtaskAddr = {
  type: "pickup" | "middle" | "dropoff";
  name: string;
  address: string;
};

interface IActiveProvidersMap {
  taskId: number;
  pickup: TtaskAddr[];
  middle: TtaskAddr[];
  dropoff: TtaskAddr[];
}

const ActiveProvidersMap = (props: IActiveProvidersMap) => {
  //@ts-ignore
  const { taskId, pickup, middle, dropoff } = props;
  //@ts-ignore
  const [map, setMap] = useState(null);
  //@ts-ignore
  const [geos, setGeos] = useState<TGeo[] | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  const [infoWindow, setInfoWindow] = useState<any>(null);
  const ref = React.useRef<any>();
  ref.current = { infoWindow, map };
  useEffect(() => {
    if (taskId) {
      fetch("tasks/getAllGeos", {
        method: "POST",
        body: JSON.stringify({ taskId }),
      })
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          if (data.geos) {
            const processedGeos =
              data.geos?.map((tg: ServerTGeo) => ({
                taskId: tg.taskId,
                createdAt: moment.tz(tg.createdAt, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY hh:mm A"),
                lng: tg?.geo?.coordinates[0],
                lat: tg?.geo?.coordinates[1],
              })) || [];
            setGeos(processedGeos);
          } else toast.error("no route found");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [taskId]);

  //@ts-ignore
  const _distanceBetweenTwoLocations = (oldLocation: any, newLocation: any) => {
    if (oldLocation == null || newLocation == null) {
      // Api.debug("_distanceBetweenTwoLocations 999999.1");
      return 999999.1;
    } else {
      const lat1 = oldLocation.lat;
      const lat2 = newLocation.lat;
      const lon1 = oldLocation.lng;
      const lon2 = newLocation.lng;

      const R = 6371e3; // metres
      const fi1 = (lat1 * Math.PI) / 180; // φ, λ in radians
      const fi2 = (lat2 * Math.PI) / 180;
      const deltaFi = ((lat2 - lat1) * Math.PI) / 180;
      const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

      const a =
        Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) +
        Math.cos(fi1) *
          Math.cos(fi2) *
          Math.sin(deltaLambda / 2) *
          Math.sin(deltaLambda / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // in metres

      if (isNaN(d)) {
        // Api.debug("_distanceBetweenTwoLocations 999999.2");
        return 999999.2;
      }

      // Api.debug("_distanceBetweenTwoLocations d - $d");
      return d;
    }
  };
  //@ts-ignore
  const LOCATION_FILTER_M = 30;
  const LOCATION_MAX = 20;
  const calculateAndDisplayRoute = (
    initialMap: any,
    directionsService: any,
    directionsDisplay: any,
    geos: TGeo[]
  ) => {
    //regular mode - when we have correct geos, first geo is start, last is end
    const origin = new google.maps.LatLng(geos[0].lat, geos[0].lng);
    const destination = new google.maps.LatLng(
      geos[geos.length - 1].lat,
      geos[geos.length - 1].lng
    );
    geos.shift();
    geos.pop();
    let filteredGeos: any = [null];
    // if(geos.length > 20) {
    //   geos.forEach((e)=>{
    //     if(_distanceBetweenTwoLocations(filteredGeos[filteredGeos.length-1], e) > LOCATION_FILTER_M) {
    //         filteredGeos.push(e);
    //     }
    //   });
    // } else {
    //   filteredGeos = geos;
    // }
    if (geos.length > LOCATION_MAX) {
      const divider = parseInt((geos.length / LOCATION_MAX).toString());
      for (let i = 0; i < geos.length; i++) {
        if (i % divider === 0) {
          filteredGeos.push(geos[i]);
        }
      }
    } else {
      filteredGeos = geos;
    }
    filteredGeos.shift();
    console.log("geos.length", geos.length, geos);
    console.log("filteredGeos.length", filteredGeos.length, filteredGeos);
    const waypoints: any = filteredGeos.map((geo: TGeo) => ({
      stopover: false,
      location: new google.maps.LatLng(geo.lat, geo.lng),
    }));

    const customIcon = {
      url: recIcon,
      scaledSize: new google.maps.Size(18, 18),
      anchor: new google.maps.Point(9, 9)
    };

    filteredGeos.map((geo: TGeo) => {
      
      const marker = new google.maps.Marker({
        map:initialMap,
        position: new google.maps.LatLng(geo.lat, geo.lng),
        icon: customIcon
      });
      marker.setZIndex(999);

      marker.addListener("click", () => {
        const { infoWindow } = ref.current;

        infoWindow.setContent(`${moment(geo.createdAt).format("hh:mm A")}`)
        infoWindow.open({
          anchor: marker,
          map:initialMap,
        });
      });
    })


    directionsService.route(
      {
        origin,
        destination,
        // avoidTolls: true,
        // avoidHighways: false,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints,
      },
      function (response: any, status: any) {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        } else {
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };

  const displayTaskInfoAsMarkers = (
    initialMap: any,
    pickup: TtaskAddr[],
    middle: TtaskAddr[],
    dropoff: TtaskAddr[]
  ) => {
    const geocoder = new google.maps.Geocoder();
    [...pickup, ...middle, ...dropoff].forEach((tAddr) => {
      if (tAddr.address) {
        geocoder.geocode(
          { address: tAddr.address },
          function (results: any, status: any) {
            if (status == "OK") {
              initialMap.setCenter(results[0].geometry.location);
              new google.maps.Marker({
                map: initialMap,
                position: results[0].geometry.location,
                title: `type: ${tAddr.type}: ${tAddr.address}`,
              });
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    if (geos?.length && geos?.length > 1) {
      setNotification(null);
      const initialMap = new google.maps.Map(
        document.getElementById("providerPathMap"),
        {
          zoom: 9,
          mapTypeControl: false,
          streetViewControl: false,
          center: { lat: geos[0].lat, lng: geos[0].lng }, //New York Center
        }
      );
      setInfoWindow(new google.maps.InfoWindow({
        content: "",
      }));

      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer({
        map: initialMap,
      });
      // const gPoints = geos.map((geo) => new google.maps.LatLng(geo.lat, geo.lng));
      // const markers = gPoints.map((gPoint, num) => new google.maps.Marker({
      //   position: gPoint,
      //   // label: `gPoint_${num}`,
      //   label: num,
      //   map: map
      // }));
      calculateAndDisplayRoute(initialMap, directionsService, directionsDisplay, geos);
      displayTaskInfoAsMarkers(initialMap, pickup, middle, dropoff);
      setMap(initialMap);
      
    } else {
      setNotification("Not enough geodata to display route");
    }
  }, [geos, pickup, middle, dropoff]);

  return (
    <>
      <div className="clearfix" />
      <div className="geosMapWrap" style={{ margin: "50px 0" }}>
        <h3>Provider route by geolocations</h3>
        {notification}
        <div style={{ height: "300px" }} id="providerPathMap" />
      </div>
    </>
  );
};

export default ActiveProvidersMap;
