import {Shop} from '../../_models/Shop'
import { toast } from 'react-toastify';

  export function updateShop(this:any, event:any) {
    event.preventDefault()
    this.setState({updating: true})
    fetch('shops/edit', {
      method: 'POST',
      body: JSON.stringify(this.state.shop)
    }).then(results => {
      return results.json();
    }).then(data => {
        let shop = new Shop(data.shop)
        shop.updated = true
        if(!this.state.shop.id) {
          //New shop, go back
          this.props.history.push("/shops")
          toast.success("Shop created successfuly")
        } else {
          //It's an update
          toast.success("Shop updated successfuly")
          this.setState({shop, updating: false})
        }
    }).catch(err => {
      toast.error(err.message);
      this.setState({updating: false})
    })


  }

  export function removeShop(this:any, id: number, index: number, event:any) {
    event.preventDefault()
    let confirmed = confirm("Are you sure?")
    if(!confirmed) return
    fetch('shops/delete', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(() => {
      let shops = this.state.shops
      shops.splice(index, 1)
      toast.success("Shop removed successfuly")
      this.setState({shops})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  export function handleSelectChange(this:any, selectedOption:any) {
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      } else {
        let campaign = this.state.campaign
        campaign.updated = false
        this.setState({campaign})
      }
    });
  }




  export function handleShopChange(this:any, event: any) {
    let shop = this.state.shop
    shop[event.target.name] = event.target.value
    shop.updated = false
    this.setState({shop});
  }

  export function editPhone(this:any, phoneNum:any) {
    let shop = this.state.shop
    shop.phone = phoneNum || ""
    shop.updated = false
    this.setState({shop});
  }
