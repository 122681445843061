import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/logo.png';

import './Nav.css';


export default class Nav extends React.Component<any, any> {
  toggleDesktopMenu: any
  constructor(props: any) {
    super(props);
    this.state = {
      userMenuOpen: false,
      mobileMenuOpen: false,
      desktopMenuOpen: true,
      unassignedTasks: 0
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
    this.toggleDesktopMenu = props.toggleDesktopMenu.bind(this)
  }


  toggleMenu() {
    this.setState({userMenuOpen: !this.state.userMenuOpen})
  }

  toggleMobileMenu() {
    this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
  }

  logout() {
      localStorage.setItem('account', "{}");
      window.account = null
      window.location.href = '/login'
  }

  componentDidMount() {
    this.setState({
      desktopMenuOpen: this.props.desktopMenuOpen,
      unassignedTasks: this.props.unassignedTasks
    })
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      desktopMenuOpen: nextProps.desktopMenuOpen,
      unassignedTasks: nextProps.unassignedTasks
    })
  }



  public render() {
    let navClass = ""
    if(window.innerWidth <= 768 && this.state.mobileMenuOpen) {
      navClass = "open"
    } else if(window.innerWidth > 768 && this.state.desktopMenuOpen) {
      navClass = "open"
    }
    return (

      <nav className={navClass}>
        <div className="toggleDesktopMenu" onClick={this.toggleDesktopMenu.bind(this)}><i className="fas fa-angle-down"></i></div>
        <li onClick={this.toggleMobileMenu} className={this.state.mobileMenuOpen? "mobileIcon fas fa-times" : "mobileIcon fas fa-bars"}></li>
        <div className="profile">
          <div className="image" style={{backgroundImage: "url("+window.account.picture+")"}}>
            {this.state.unassignedTasks>0 && <div className="bell">
              {this.state.unassignedTasks}
              <span>You have {this.state.unassignedTasks} unassigned tasks in the next 48 hours</span>
            </div>}
          </div>
          <span onClick={this.toggleMenu.bind(this)} className="name">{window.account.name} <i className="fas fa-angle-down"></i></span>
          <ul className={"userMenu " + (this.state.userMenuOpen? "open":"")}>
            <li>Edit profile</li>
            <li onClick={this.logout} style={{color: "#e13c34"}}>Logout</li>
          </ul>
        </div>
        <ul className="mainMenu">
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to={"/tasks"}>Tasks</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to={"/activeProvidersMap"}>Active Providers Map</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/projects">Projects</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/providers">Providers</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/clients">Clients</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/cars">Vehicels</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/shops">Shops</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/addresses">Addresses</NavLink>
          <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/analytics">Analytics</NavLink>

          {window.innerWidth > 768 && <NavLink activeClassName={"active"} onClick={this.toggleMobileMenu} tabIndex={-1} to="/chat">Chat</NavLink>}

          {/*window.account.role == "admin" && <NavLink tabIndex={-1} activeClassName={"active"} to="/users">Users</NavLink>*/}
        </ul>
        <Link to={"/"}><img src={logo} /></Link>
      </nav>
    );
  }
}
