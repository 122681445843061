export class Client {
  public id: number
  public name: string
  public phone: string
  public email: string
  public updated: boolean
  public qboId: number
  public syncToken: number
  public subClients: any[]
  public projects: any[]
  constructor(client:any = {}) {
    this.id = client.id || null
    this.name = client.name || ""
    this.phone = client.phone || ""
    this.email = client.email || ""
    this.projects = (client.projects && client.projects.length)? client.projects : []
    this.updated = true
    this.qboId = client.qboId || null
    this.syncToken = client.syncToken || null
    this.subClients = (client.subClients && client.subClients.length)? client.subClients : []

  }
}
