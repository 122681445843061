import * as React from 'react';
import Select from 'react-select';
import {Provider} from '../../../_models/Provider'
import { toast } from 'react-toastify';
import {TaskStatus} from '../../../_models/Task'

import {customSelectStyles, themeStyles} from './themeStyles.js'
import './popup.css';

export class AssignProvider extends React.Component<any, any> {
  closePopup: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loadedTask: null,
      providers: [],
      subProviders: []
    }
    this.closePopup = this.props.closePopup
  }


  componentDidMount() {
    this.setState({
      providers: this.props.providers,
      loadedTask: this.props.task
    })
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      providers: nextProps.providers,
      loadedTask: nextProps.task
    })
  }

  chooseProvider(selectedOption:any) {
    let task = this.state.loadedTask

    if(task.status == TaskStatus.completed || task.status == TaskStatus.active) {
      toast.warn("It is not possible to reassign/unassign a provider from an active/completed task")
      return
    }
    this.setState({ selectedOption }, () => {

      if(selectedOption.value) {

        fetch('providers/get', {
          method: 'POST',
          body: JSON.stringify({id: selectedOption.value})
        }).then(results => {
          return results.json();
        }).then(data => {
            let provider = new Provider(data.provider)
            let subProviders = provider.subProviders.map((subProvider: any) => {
              return {
                value: subProvider.id,
                label: subProvider.name
              }
            })
            task.provider = provider
            task.subProvider = null
            task.updated = false
            this.setState({loadedTask: task, subProviders})
        }).catch(err => {
          toast.error(err.message);
        })


      } else {
        task.provider = null
        task.subProvider = null
        task.updated = false
        task.status = TaskStatus.unassigned
        this.setState({loadedTask: task, subProviders: []})
      }
    });
  }

  chooseSubProvider(selectedOption:any) {
    let task = this.state.loadedTask

    if(task.status == TaskStatus.completed || task.status == TaskStatus.active) {
      toast.warn("It is not possible to reassign/unassign a provider from an active/completed task")
      return
    }
    this.setState({ selectedOption }, () => {
      if(selectedOption.value) {
        task.subProvider = {
          id: selectedOption.value,
          name: selectedOption.label
        }
        task.updated = false
        this.setState({loadedTask: task})

      } else {
        task.subProvider = null
        task.updated = false
        task.status = TaskStatus.unassigned
        this.setState({loadedTask: task, subProviders: []})
      }
    });
  }

  changeReminders(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let task = this.state.loadedTask
    task[name] = value
    task.updated = false
    this.setState({loadedTask: task});
  }

  public render() {
    let task = this.state.loadedTask
    let provider = task && task.provider
    let subProvider = task && task.subProvider

    return (
      <div id="popup-root">
          <i onClick={this.closePopup} className="fas fa-times"></i>

          <div className="container" style={{maxWidth: "430px"}}>
            {task && <div>
            <h2>Assign Provider:</h2>
            <div className="input">
              <label>Select Provider:</label>
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={provider && {value: provider.id, label: provider.name}}
                  onChange={this.chooseProvider.bind(this)}
                  options={this.state.providers}
                  menuPlacement={"bottom"}
                  placeholder={"Select"}
                />
              </div>
              <div className="clearfix"></div>
            </div>
            {(subProvider || this.state.subProviders.length > 0) && <div className="input">
              <label>Select Sub Provider:</label>
              <div className="selectContainer">
                <Select
                  theme={themeStyles}
                  styles={customSelectStyles}
                  className="multiSelect"
                  classNamePrefix="react-select"
                  value={subProvider && {value: subProvider.id, label: subProvider.name}}
                  onChange={this.chooseSubProvider.bind(this)}
                  options={this.state.subProviders}
                  menuPlacement={"bottom"}
                  placeholder={"Select"}
                />
              </div>
              <div className="clearfix"></div>
            </div>}

            <div className="input">
              <label>Set Reminder:</label>
              <input type="checkbox" name="hourReminder" onChange={this.changeReminders.bind(this)} checked={task.hourReminder} /><span>1 hour before</span>
              <input type="checkbox" name="dayReminder" onChange={this.changeReminders.bind(this)} checked={task.dayReminder} /><span>1 day before</span>
              <div className="clearfix"></div>
            </div>
            </div>}
          </div>

      </div>
    )
  }

}
