import * as React from 'react';
import { toast } from 'react-toastify';
import * as Moment from 'moment-timezone'
import Contact from './Contact';

export default class Messages extends React.Component<any, any> {
  socket: any
  chat: any
  senderName:any
  textBox: any
  chatLines: number = 1
  constructor(props: any) {
    super(props);
    this.state = {
      chat: null
    }
    this.loadChat = this.loadChat.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.senderName = props.senderName
    this.handleChatChange = this.handleChatChange.bind(this)
    this.recieveMessage = this.recieveMessage.bind(this)
  }


  componentDidMount() {
    this.loadChat(this.props.selectedChat)
    let chatObserver = new MutationObserver((e:any) => {
      if (e[0].addedNodes) this.chat.scrollTop = this.chat.scrollHeight
    });

    chatObserver.observe(this.chat, { childList: true });
  }

  componentWillReceiveProps(nextProps: any) {
    if(!this.state.chat || nextProps.selectedChat != this.state.chat.id)
      this.loadChat(nextProps.selectedChat)
  }

  loadChat(id: number) {
    if(!id) return
    fetch('chats/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
      this.setState({chat: data.chat, contactPopup:false})
    }).catch(err => {
      toast.error(err.message);
    })
  }


  recieveMessage(message: any) {
    let chat = this.state.chat
    if(message && chat && chat.id == message.chatId) {
      chat.messages.push(message)
      this.setState({chat})
    }
  }
  componentWillMount() {
    this.socket = this.props.socket
    this.socket.on('newChatMessage', this.recieveMessage);
  }

  componentWillUnmount() {
    if(this.socket)
      this.socket.removeListener('newChatMessage', this.recieveMessage)
  }

  renderMessage = (message: any) => {
    let phoneRegex = new RegExp(/(((\d)\D)?(\(?(\d\d\d)\)?)?\D(\d\d\d)\D(\d\d\d\d))|(\b\d{10,11}\b)/g)
    let linkRegex = new RegExp(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm)
    let date = Moment.tz(message.createdAt, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY hh:mm A")
    let body = message.body.split('\n').map((item:any, i:number) => {
        let markup = item.replace(phoneRegex, (phone:any) => {
          return '<a href="tel:'+phone+'">'+phone+'</a>'
        });
        markup = markup.replace(linkRegex, (link:any) => {
          return '<a target="_blank" href="'+link+'">'+link+'</a>'
        })
        return <p style={{display: "inline"}} key={i}><em dangerouslySetInnerHTML={{__html: markup}} /><br /></p>;
    })
    let messageClass = ""
    if(!message.userId && !message.notification) {
      messageClass = "message left"
    } else {
      messageClass = "message right"
      if(message.notification) {
        messageClass += " notification"
      }
    }
    return <div key={message.id} className={messageClass}><span>{date}</span>{body}</div>

  }

  handleChatChange(dividor: number) {
    let spacesCount = (this.textBox.value.match(/\n/g) || []).length;
    this.chatLines = Math.ceil(this.textBox.value.length/dividor) + spacesCount

    if(this.chatLines > 1) {
      this.textBox.style.height = this.chatLines * 40 + "px"
      this.chat.style.height = "calc(100% - "+ this.chatLines * 40 + "px)"
    } else {
      this.textBox.style.height = "50px"
      this.chat.style.height = "calc(100% - 50px)"
    }
  }

  sendMessage() {
    if(this.textBox.value == "") return
    fetch('chats/sendSMS', {
      method: 'POST',
      body: JSON.stringify({phone: this.state.chat.phone, body: this.textBox.value, userId: window.account.id})
    }).then(() => {
      this.textBox.value = ""
      this.textBox.style.height = "50px"
      this.chat.style.height = "calc(100% - 50px)"
      this.chat.scrollTop = this.chat.scrollHeight
    }).catch(err => {
      toast.error(err.message);
    })
  }

  keyPress(dividor: number, event: any) {
    if(event.keyCode == 13 && event.shiftKey) {
      event.preventDefault()
      this.textBox.value += "\n"
      this.handleChatChange(dividor)
      this.textBox.scrollTop = this.textBox.scrollHeight
    } else if(event.keyCode == 13) {
      event.preventDefault()
      this.sendMessage()
    }
  }

  done() {
    fetch('chats/done', {
      method: 'POST',
      body: JSON.stringify({id: this.state.chat.id})
    }).catch(err => {
      toast.error(err.message);
    })
  }

  togglePopup() {
    this.setState({contactPopup: !this.state.contactPopup})
  }


  public render() {
    let unknownContact = false
    if(this.state.chat) {
      let senderName = this.senderName(this.state.chat)
      if(senderName == this.state.chat.phone) unknownContact = true
    }
    return (
      <div id="messages-root">

        {this.state.contactPopup &&
          <Contact
            socket={this.props.socket}
            chat={this.state.chat}
            closePopup={this.togglePopup.bind(this)}
          />}

        {this.state.chat && <div className="header">
          {this.senderName(this.state.chat)}
          <a onClick={this.done.bind(this)}>Finish Chat</a>
          <a onClick={this.togglePopup.bind(this)}>{unknownContact? "Add" : "Update"} Contact</a>
        </div>}
          <div className="chatBox" ref={(e: any) => {this.chat = e}}>
          {this.state.chat && this.state.chat.messages.map((message: any, i:number) => {
            return this.renderMessage(message)
          })}
        </div>

        <div className="sendBox">
          <textarea readOnly={!this.state.chat} placeholder="Type message..."
                  onChange={this.handleChatChange.bind(this, 150)}
                  maxLength={1500}
                  onKeyDown={this.keyPress.bind(this, 150)}
                  ref={(e: any) => {this.textBox = e}}
                  style={{height: "50px"}}></textarea>

          <span onClick={this.sendMessage.bind(this)} className="sendMsg">Send</span>

        </div>

      </div>
    )
  }

}
